import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../../components/Header/Header'
import Menu from '../../../components/Menu/Menu'
import { Switch, RouteWithSubRoutes } from 'react-router-dom'
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute'
import { withRouter } from 'react-router-dom'
import * as Admin from '../store/admin';
import http from '../../../http/http'
import { Ajax } from '../../../utils/ajax'
import { message } from 'antd'
import { Link } from 'react-router-dom';
import './admin.less'
const $ = window.$;
const location = window.location
function RenderRouters( {routeConfig} ) {
    return routeConfig.map((item, index) => {
        console.log(item)
        return <Fragment key={index}>
            {/* {item.Link !== '/admin/listPage' ? <Menu xcxList={this.state.xcxList} selectXCX={this.selectXCX.bind(this)} selectNowXCX={this.selectNowXCX.bind(this)}></Menu> : ''} */}
            <PrivateRoute
                path={item.Link}
                component={item.component}
                hasHeader={item.hasHeader}
                router={item.router}
            ></PrivateRoute>
        </Fragment>
    })
}
class admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // xcxList:[]
            
        }
    }
    componentDidMount() {
        var viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', '');
        sessionStorage.setItem("haveReturn", false)
    }
    componentWillMount() {

    }
    componentWillUnmount() {
   
    }
    componentWillUnmount(){
        var viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', 'width=device-width, initial-scale=1');
    }
    

 
    

    render() {
        console.log(this.props)
        const { routes } = this.props;
        console.log(routes)
        return (
            <div className="adminPage" >
                <Header></Header>
                    <Switch>
                        <RenderRouters routeConfig={routes}></RenderRouters>
                    </Switch>
                
            </div>
        );
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(admin));