import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'
import './mainPage.less'
import Tips from '../../../components/Tips/Tips'
import icon from '../../../assets/images/xcx.jpg'
import http from '../../../http/http'
import Menu from '../../../components/Menu/Menu'
import fabu from '../../../assets/images/fabu-.png'
import { Ajax } from '../../../utils/ajax'
import * as Admin from '../store/admin';
import { message, Modal } from 'antd';
import { getAuthCode } from '../common/utils'
class mainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            image: '',
            previewVisible: false,
            stateTime: 3
        }
    }
    componentDidMount() {
        if(this.props.history.location.state&&this.props.history.location.state.id){
            this.getData(this.props.history.location.state.id);
        }
    }
    componentWillReceiveProps(nextProps) {
    }
    componentWillMount() {
    }
    componentWillUnmount(){

    }
    getData(id) {
        Ajax.GET(`/ldmshop/merchant/one/${id}`)
            .then(res => {
                if (res && res.code == 0) {
                    sessionStorage.setItem("key", 2);
                    this.props.actions.setShopData(res.data)

                } else {
                    message.error(res.errmsg)
                }
            })
    }
    goToInformation() {
        let merchantId = this.props.adminReducer.shopData.merchantId
        if (merchantId != undefined) {
            // getAuthCode(merchantId);
            console.log(getAuthCode)
            getAuthCode(merchantId,"","","/admin/test",11);
            // let xhr = new XMLHttpRequest();
            // // xhr.open('GET', `/ldmshop/auth/officialScanGuideAuth?merchantId=undefined`, true);
            // xhr.open('GET', `/ldmshop/auth/officialScanGuideAuth?merchantId=${this.props.adminReducer.shopData.merchantId}`, true);
            // let backToken = sessionStorage.getItem("backToken") == null ? '' : sessionStorage.getItem("backToken")
            // xhr.setRequestHeader('back_token', backToken);
            // xhr.responseType = 'arraybuffer'; // 重点
            // let that = this; // 这个不是必须，只是用来保存this的指向
            // xhr.onload = function (e) {
            //     if (this.status == 200) {
            //         let result = this.response;
            //         console.log(result)
            //         let imgUrl = `data:image/png;base64,${window.btoa(
            //             new Uint8Array(result).reduce(
            //                 (data, byte) => data + String.fromCharCode(byte),
            //                 ""
            //             )
            //         )}`;
            //         that.setState({
            //             isShow:true,
            //             image:imgUrl
            //         })
            //         that.goData();
            //     }

            // };
            // xhr.send();
        }
        else {
            this.setState({
                previewVisible: true
            })

            // this.state.stateTime = 3
            var that = this
            var time = setInterval(function () {
                var nowNum = that.state.stateTime;
                if (nowNum == 1) {
                    clearInterval(time);
                    window.location.href = '/Choose';
                }
                else {
                    that.setState({ stateTime: nowNum - 1 })
                }

            }, 1000)

            // setTimeout(() => {
            //     window.location.href = '/home';
            // }, 3000)
        }

    }
    closeTip() {
        this.setState({
            isShow: false
        })
        if (!!this.props.adminReducer.shopData.mainAppId) {
            this.props.history.replace("/admin/Auth")
        }
    }
    handleCancel() {
        // this.setState({
        //     previewVisible:false
        // })
    }


    render() {
        console.log(this.props.adminReducer.shopData.mainAppId);
        if (!!this.props.adminReducer.shopData.mainAppId) {
            this.props.history.replace("/admin/Auth")
        }
        return <section style={{minWidth:"1200px"}}>
            <Menu></Menu>
            <div className="mainPage" style={{minWidth:"826px"}}>
            <div className="title">
            微信小程序配置
            </div>
            <div className="tabs">
                <span className="active">
                    小程序授权
                </span>
                <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span>
                    小程序预览
                </span>
                <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                <span>
                    小程序提交审核
                </span>
                <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                <span>
                    小程序完成发布
                </span>
            </div>
            <div className="content">
                <div className="item">
                    <div className="name">
                        我已经有小程序了
                    </div>
                    <div className="button" onClick={this.goToInformation.bind(this)}>
                        我有小程序，立即去授权<span className="iconshuangjiantou-copy iconfontAdmin"></span>
                    </div>
                    <div className="text">
                        为保证小程序创建顺利，需要小程序管理员把小程序授权给SIFSHOP，授权时请保持默认选择，请正确选择要授权的小程序。
                    </div>
                    {/* <a href="https://developers.weixin.qq.com/miniprogram/introduction" target="_blank">
                    <div className="fooder">
                        如何完成授权？
                    </div>
                    </a> */}
                    
                </div>
                <div className="item">
                    <div className="name">
                        我还没有小程序
                    </div>
                    <a target="_blank" href="https://mp.weixin.qq.com/cgi-bin/registermidpage?action=index&lang=zh_CN&token=">
                        <div className="button Bgcolor" >
                            去微信公众平台申请<span className="iconshuangjiantou-copy iconfontAdmin"></span>
                        </div>
                    </a>
                    <div className="text">
                        创建小程序必须先注册小程序账户及相关设置
                    </div>
                    <a href="https://developers.weixin.qq.com/miniprogram/introduction" target="_blank">
                        <div className="fooder">
                            如何完成注册？
                    </div>
                    </a>
                </div>
            </div>
            <Tips
                title={"请扫码授权"}
                isShow={this.state.isShow}
                closeTip={this.closeTip.bind(this)}
                width='400px'
                height='430px'
            >
                <div className="tipView">
                    <img src={this.state.image}></img>
                </div>
            </Tips>
            <Modal
                visible={this.state.previewVisible}
                footer={null}
                bodyStyle={{ width: '1000px', height: '600px', paddingTop: '200px' }}
                onCancel={this.handleCancel.bind(this)}>
                <span className="zanwei">您暂未注册商城</span>
                <div className="tiaozhuan">
                    <img src={fabu}></img>
                    <span>{this.state.stateTime}秒后即将跳转至注册页面......</span>
                </div>

            </Modal>
        </div>
        </section>
        
    }
}

const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    // actions: bindActionCreators(Store, dispatch)
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(mainPage));