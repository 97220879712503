export default {
    /*
    * 注册
    * COMMON
    * CHIOSCE_XCX_ID：选择商店
    * SELECT_XCX_DATA:选择小程序
    * SET_XCX_ID:选择当前小程序的id
    * 
    * NOW_IN_SHOP:当前的店铺
    * SHOP_INFO:店铺信息
    * */
    CHIOSCE_XCX_ID:'CHIOSCE_XCX_ID',
    SELECT_XCX_DATA:'SELECT_XCX_DATA',
    SET_XCX_ID:'SET_XCX_ID',

    NOW_IN_SHOP:"NOW_IN_SHOP",
    SHOP_INFO:"SHOP_INFO"
}