import React, { Component } from 'react';
import { Route ,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as common from '../../redux/action/common';

class PrivateRoute extends Component{
    
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount() {
        const { history } = this.props;
    //    history.replace(`/Home`);
    }
    componentWillMount() {
    }

    render() {
        const {hasHeader ,router ,path , component , ...rest } = this.props;
        return <Route
            path={path}
            render={props => (
                // pass the sub-routes down to keep nesting
                <this.props.component {...props} routes={router} />
            )}
        />
        // return   <Route   path={Link} render={(props) => (
            
        //         <Component {...props} routers={router} />
            
        // )} >
        //     {/* {children.length>0? this.childrenRouter(children):''} */}
        // </Route>
    }
}
const mapStateToProps = ({common}) => {
    return common
  }

export default withRouter(connect(mapStateToProps,null)(PrivateRoute));