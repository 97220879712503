import axios from 'axios'
import { message } from 'antd';

const instance = axios.create({
    withCredentials: true // 跨域类型时是否在请求中协带cookie
})
const getNewHeaders = () => {
    let back_token = sessionStorage.getItem("backToken");
    console.log(back_token)
    if(!!back_token){
    return {"Content-Type":"application/x-www-form-urlencoded","back_token":back_token}
    }else{
        return {"Content-Type":"application/x-www-form-urlencoded"}
    }
}
function redirect(data){
    if(data.code == 50070){
        sessionStorage.removeItem("backToken");
        setTimeout(()=>{
            window.location.href="/home"
        },2000)
    }
}

export default class HttpUtil {
    static get(url, params = {},headers) {
        return new Promise((resolve, reject) => {
            instance.get(url, {params, headers:headers|| getNewHeaders()},).then(({data}) => {
                console.log(data)
                redirect(data);
                resolve(data);
            }).catch((err) => {
                reject({err})
            })
        })
    }
    static delete(url, params = {}) {
        return new Promise((resolve, reject) => {
            instance.delete(url, {params, headers: getNewHeaders()},).then(({data}) => {
                redirect(data);
                resolve(data);
            }).catch((err) => {
                reject({err})
            })
        })
    }
    static post(url, params = {},headers) {
        // let pa = qs.stringify(params);
        return new Promise((resolve, reject) => {
            instance.post(url, params, {headers: headers||getNewHeaders()}).then(({data}) => {
                redirect(data);
                resolve(data)
            }).catch((err) => {
                reject({err: JSON.stringify(err)})
            })
        })
    }

    static formDataPost(url, params = {}) {
        return new Promise((resolve, reject) => {
            instance.post(url, params, {headers: getNewHeaders()}).then(({data}) => {
                redirect(data);
                resolve(data)
            }).catch((err) => {
                reject({err: JSON.stringify(err)})
            })
        })
    }

    static put(url, params = {}) {
        // let pa = qs.stringify(params);
        return new Promise((resolve, reject) => {
            instance.put(url, params, {headers: getNewHeaders()}).then(({data}) => {
                resolve(data)
            }).catch((err) => {
                reject({err})
            })
        })
    }
}
