// require('./UserSetting.css');
// require('../../utils/upload');
import './UserSetting.css';
// import '../../utils/upload';

import PhoneModal from './PhoneModal';
import BackHeader from '../../../common/BackHeader'
// import SideBar from '../../../common/SideBar'
import Footer from '../../../common/Footer'
import PromptModal from '../../../common/PromptModal'
import { Modal } from '../../../common/modal';
import { Link } from 'react-router-dom';
import Menu from '../../../components/Menu/Menu'
import { timingSafeEqual } from 'crypto';
import UploadImg from '../../../components/UploadImages/index'
import { Ajax } from '../../../utils/ajax';
var React = require("react"); var createReactClass = require('create-react-class');
const $ = window.$;
const location = window.location
export default createReactClass({
  getInitialState: function () {
    return {
      activeTab: 0,
      checked: 0,
      showModal: false,
      defaultImg: true,
      imgerror: false,
      stateTime: 60,
      disabled: false,
      codeBoxShow: false,
      userPhone: '',
      imgList: [],
      avatar: "http://lingdaima.dotlinkface.com/avatar-default.png",
      infoType: true//调用弹窗信息类型  错误信息 或者正确信息  显示不同图标
    };
  },

  componentDidMount: function () {
    var avatar = $(this.refs['avatar-preview']);
    var username = $(this.refs['username']);
    var sex = $(this.refs['sex']);
    var email = $(this.refs['email']);
    var phone = $(this.refs['phone']);
    let morenPhone = sessionStorage.getItem("phoneNumber")
    let id = sessionStorage.getItem("shopData")
    let backToken = sessionStorage.getItem("backToken")
    id = JSON.parse(id)
    console.log(id.merchantId)
    this.setState({
      morenPhone,
      backToken,
      nowMerchantId: id.merchantId
    })


    this.getData()
    // $.get('/json/my/userSetting', (result) => {
    //   if (result.success) {
    //     var init = result.data;
    //     this.setState({ defaultImg: !init.logo });
    //     this.setState({ useremail: init.email });
    //     avatar.attr({ src: (init.logo || 'http://lingdaima.dotlinkface.com/avatar-default.png') });
    //     username.val(init.username);
    //     this.setState({hideImg: init.logo ? false : true, checked: init.sex || 0});
    //     email.val(init.email);
    //     this.setState({
    //       userPhone: init.phone
    //     })
    //     phone.val(init.phone);
    //     this.phone = init.phone;
    //   }
    // });

    this.uploadImg();

    email.on('blur', () => {
      this.checkoutEmail();
    });

    username.on('blur', () => {
      this.checkoutUsername();
    });
  },

  getData: function () {
    Ajax.GET('/ldmshop/admin/getUser')
      .then(res => {
        if (res && res.code == 0) {
          console.log(res)
          let imgList = this.state.imgList
          imgList[0] = res.data.headImg
          let checked = res.data.gender == 0 ? 0 : 1
          this.setState({
            name: res.data.name,
            email: res.data.email,
            checked,
            imgList
          })
        } else {

        }

      })
      .catch(error => {
        console.log(error)
      })
  },

  uploadImg: function () {
    // var self = this;
    // var upload = $('#avatar-btn').html5_upload({
    //   url: '/json/my/userlogoUpload',
    //   sendBoundary: window.FormData || $.browser.mozilla,
    //   fieldName: 'avatar',
    //   onStart: function (event, total) {
    //     var imgerror = ''

    //     let iMaxFilesize = 1 * 1024 * 1024; //3MB

    //     var oFile = document.getElementById('avatar-btn').files[0];

    //     let rFilter = /^(image\/gif|image\/jpeg|image\/png)$/i;

    //     if (!rFilter.test(oFile.type)) {
    //         document.getElementById('avatar-btn').value='';
    //         self.setState({
    //             imgerror : '图片类型出错'
    //         })

    //         return false;
    //     }

    //     if (oFile.size > iMaxFilesize) {
    //         document.getElementById('avatar-btn').value='';
    //         self.setState({
    //             imgerror : '图片超过大小限定'
    //         })

    //         return false;
    //     }
    //     return true;
    //   },
    //   onFinishOne: function (e, res, name, number, total) {
    //     var obj = JSON.parse(res);
    //     self.setState({logo: obj.logo, defaultImg: false, resText: '图片已上传'});
    //     console.log(obj);
    //     var avatar = $(self.refs['avatar-preview']);
    //     avatar.attr({ src: obj.logo });
    //     $('#userlogo').attr({ src: obj.logo });
    //     self.setState({
    //       imgerror : ''
    //     })
    //   },
    //   onError: function (e, name, err) {
    //     alert('error while uploading file ' + name);
    //     console.log(err);
    //   }
    // })
  },

  checkoutEmail: function () {
    var tel = $(this.refs["email"]);
    var self = this;
    var emialErrTip = $(this.refs['email-err-tip'])
    if (!/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(tel.val())) {
      this.setState({ emailError: "邮箱格式不正确" })
      emialErrTip.addClass('active')
      return false;
    }
    else {
      this.setState({ emailError: "" });
      emialErrTip.removeClass('active')
      return true;
    }
    // else if (/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(tel.val())) {
    //   if(tel.val() != self.state.useremail){
    //     $.post('/json/user/isregistered', {account:tel.val().trim()}, function(data){
    //       if(data.success){
    //         self.setState({emailError:""});
    //         emialErrTip.removeClass('active')
    //         return true;
    //       } else {              
    //         self.setState({emailError:"该邮箱账号已被注册！",emailState: 0})
    //         emialErrTip.addClass('active')
    //         return true;
    //       }
    //     })
    //   } else {
    //     this.setState({emailError:""});
    //     emialErrTip.removeClass('active')
    //     return true;
    //   }
    //   return true
    // }
    // return false;
  },

  checkoutUsername: function () {
    var username = $(this.refs['username']).val().trim();
    var nameErrTip = $(this.refs['name-err-tip'])
    if (!username) {
      this.setState({ usernameError: '用户名不能为空' });
      nameErrTip.addClass('active')
    } else if (username.length < 2 || username.length > 16) {
      this.setState({ usernameError: "用户名长度在2,16之间" })
      nameErrTip.addClass('active')
    } else if (!/^[\w\u4e00-\u9fa5_]*$/.test(username)) {
      this.setState({ usernameError: '只由数字、字母、汉字或者下划线组成' });
      nameErrTip.addClass('active')
    } else {
      this.setState({ usernameError: "" });
      nameErrTip.removeClass('active')
      return true;
    }
    return false;
  },

  checkoutPhone: function () {
    var tel = $(this.refs["phone"]);
    var phoneErrTip = $(this.refs['phone-err-tip'])
    if (!tel.val().trim()) {
      this.setState({ phoneError: "手机号码不能为空" })
      phoneErrTip.addClass('active')
    } else if (!/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(tel.val())) {
      this.setState({ phoneError: "请输入正确的手机号" })
      phoneErrTip.addClass('active')
    } else {
      this.setState({ phoneError: "" })
      phoneErrTip.removeClass('active')
      return true;
    }
    return false;
  },

  sendCode: function (ev) {
    ev && ev.preventDefault();
    if (!this.checkoutPhone()) {
      return false
    }
    var phone = $(this.refs['phone']).val();
    var _this = this;
    if (this.state.userPhone == phone) {
      this.setState({
        resText: '请先更改绑定手机号之后再发送验证码',
        infoType: false
      });
      $('#prompt-modal').modal('show');
      return;
    }
    $.post("/json/user/sendcode/" + phone, function (result) {
      if (result.success) {
        _this.setState({
          disabled: true,
          codeBoxShow: true
        });
        _this.state.stateTime = 60;
        var time = setInterval(function () {
          var nowNum = _this.state.stateTime;
          if (nowNum == 0) {
            _this.setState({ "disabled": false });
            nowNum = 60;
          } else {
            _this.setState({ "stateTime": nowNum - 1 });
          }
        }, 1000)
        return true;
      } else {
        // self.setState({ codeGet: 1 });
        this.setState({ codeError: '获取验证码失败' });
        return false;
      }
    })
  },

  validateCode: function () {
    var code = $(this.refs['code']).val();
    var phone = $(this.refs['phone']).val();
    //updatephone
    $.post('/json/user/reset/updatephone', { account: phone, code: code }, (result) => {
      if (result.success) {
        // this.nextStep();
        // this.showPhoneModal()
        // this.setState({codeError: '更换手机成功'});
        return true;
      } else {
        this.setState({ codeError: result.errfor.codeerror });
        return false;
      }
    });
  },

  checkRadio: function (cur) {
    this.setState({ checked: cur });
  },

  clickInput: function () {
    $("#avatar-btn").trigger("click");
  },

  showPhoneModal: function (e) {
    if (!this.checkoutPhone()) {
      return
    }
    e.preventDefault();
    this.setState({
      showModal: true
    });
    // $(this.refs['create-modal']).modal('show');
  },

  renderModal: function () {
    if (this.state.showModal) {
      return <PhoneModal
        showModal={this.state.showModal}
        phone={this.state.morenPhone}
        closeModal={this.closeModal}>
      </PhoneModal>
    }
  },

  ChangePhone() {
    return (
      <Modal id='UserSettingTipModle' tip='更换手机号码' >
        <div className='replace-List'>
          <div className='replace-item'>
            <div className='input-div-1'>
              <label htmlFor="phone-input" >
                <i className="icon iconfont icon-shouji1"></i>
              </label>
              <span>+86</span>
              <input type="text" className="phone-input" id="phone-input" autoconplete="off" placeholder="输入新手机号码" />
            </div>
            <div className='error-tip'></div>
            <div className='input-div-1 '>
              <label htmlFor="Verification-code">
                <i className="icon iconfont icon-yanzhengma"></i>
              </label>
              <input type="text" className="Verification-code" id="Verification-code" autoconplete="off" placeholder="请输入验证码" />
              <span className='getcode'>获取验证码</span>
            </div>
            <div className='error-tip'></div>
            <div className='submit-btn'>确定</div>
          </div>
          <div className='replace-item'></div>
        </div>
      </Modal>
    )
  },

  closeModal: function () {
    this.setState({ showModal: false });
    $('.modal-backdrop').css({ display: 'none' })
    // this.forceUpdate();

  },

  isValid: function () {
    if (this.checkoutUsername()) {
      return true;
    }
    return false;
  },

  submitSetting: function (e) {
    e.preventDefault();
    var username = $(this.refs['username']).val();
    var email = $(this.refs['email']).val();
    // var codeCheck = $(this.refs['code-check']).hasClass("hide");
    // var phone = $(this.refs['phone']).val(); 
    // var sex = this.state.checked;
    if (!this.checkoutPhone()) {
      return false;
    }
    // if(codeCheck) {
    //   if(this.state.userPhone != phone) {
    //     this.setState({ 
    //       resText: '检测到你更改绑定的手机号，请获取验证码验证之后再保存更改',
    //       infoType: false  
    //     });
    //     $('#prompt-modal').modal('show');
    //     return false;
    //   }      
    // } else {
    //   if(!this.validateCode()) {
    //     return false;
    //   } 
    // } 
    if (!this.isValid()) {
      return false;
    }
    var data = {};
    if (this.state.imgList.length !== 0) {
      data.headImg = this.state.imgList[0];
    }
    if (!this.checkoutUsername()) {
      return false;
    }
    data.name = username;
    if (!this.checkoutEmail()) {
      return false;
    }
    data.email = email;
    data.gender = this.state.checked;
    data.phone = this.state.morenPhone
    data.nowMerchantId = this.state.nowMerchantId
    data.backToken = this.state.backToken

    Ajax.POST('/ldmshop/admin/updateUserInfo', data)
      .then(res => {
        if (res && res.code == 0) {
          this.setState({
            resText: '修改成功',
            infoType: true
          });
          $('#prompt-modal').modal('show');
        } else {
          this.setState({
            resText: '操作失败',
            infoType: false
          });
          $('#prompt-modal').modal('show');
        }

      })
      .catch(error => {
        console.log(error)
      })

    // $.post('/json/my/userSetting', data,  (result) => {
    //   $('#prompt-modal').modal('show');
    //   if (result.success) {
    //     this.setState({ 
    //       resText: '修改成功',
    //       infoType: true
    //     });
    //     $('#prompt-modal').modal('show');
    //   } else {
    //     this.setState({ 
    //       resText: '操作失败',
    //       infoType: false 
    //     });
    //     $('#prompt-modal').modal('show');
    //   }
    // });    
  },

  uploadImagesSuccess: function (data) {
    let arr = [];
    let imgList = this.state.imgList
    if (imgList.length == 0) {
      data.forEach(item => {
        arr.push(item.response || item.url);
      });
      this.setState({
        imgList: arr,
      }, () => {
        console.log(this.state.imgList)
      });
    }
    else if (imgList.length == 1) {
      data.forEach(item => {
        arr.splice(0, 1, item.response || item.url)
      })
      this.setState({
        imgList: arr
      }, () => {
        console.log(this.state.imgList)
      })
    }

  },
  selectXCX: function (data = {}) {
    console.log(this.props)
    console.log(data)
    this.props.actions.SetXCXId(data);
    this.getShopData(data.merchantId)
  },
  selectNowXCX: function (data = {}) {
    console.log(this.props)
    this.props.actions.setXCXData(data);
  },


  render: function () {
    return (
      <section>
        <Menu xcxList={this.state.xcxList} selectXCX={this.selectXCX.bind(this)} selectNowXCX={this.selectNowXCX.bind(this)}></Menu>

        <div className="my" style={{ width: '1000px' }}>
          {/* <BackHeader role="company"></BackHeader> */}
          <div className="my-content ">
            {/* <SideBar activeState={2} role={0}></SideBar> */}
            <div id="user-setting" className="container-fluid">
              <div className="user-settings-wrapper row">

                <div className="nav-wrapper col-md-12">
                  <ul className="col-md-12 tab-list">
                    <li
                      className={this.state.activeTab === 0
                        ? 'activeclass'
                        : ''}>
                      <Link ref="userSetting" to="/admin/userSetting">基本资料</Link>
                    </li>
                    <li
                      className={this.state.activeTab === 1
                        ? 'activeclass'
                        : ''}>

                      <Link ref="companyInfo" to="/admin/companyInfo">公司信息</Link>
                    </li>
                    <li
                      className={this.state.activeTab === 2
                        ? 'activeclass'
                        : ''}>
                      <Link ref="modifyPassword" to="/admin/modifyPassword">修改密码</Link>
                    </li>
                  </ul>
                </div>

                <div className="setting-body-wrapper">
                  <div className="tab-body-list" ref="list">
                    <div className="settings" id="settings">
                      <div className="form-row">
                        <label className="avatar-label">您的头像：</label>
                        <span className="icon iconfont icon--daxiajiantou" onClick={this.clickInput}></span>
                        <div className="avatar-wrapper">
                          <div className="avatar" id="avatar">
                            <div className='avatar-img'><img className={this.state.defaultImg ? "default-img" : ''}
                              src={this.state.imgList.length == 0 ? this.state.avatar : this.state.imgList[0]} alt="" ref="avatar-preview" /></div>

                            {/* <div className="upload">
                            <label htmlFor="avatar-btn">上传图片</label>
                            <input
                              ref="avatar-upload"
                              type="file"
                              id="avatar-btn"
                              name="avatar"
                              accept="jpg/png/gif"/>
                          </div> */}
                          </div>
                          <div className="avatar-upload">
                            <div className="upload">
                              {/* <label htmlFor="avatar-btn">上传图片</label> */}
                              <UploadImg
                                uploadSuccess={this.uploadImagesSuccess}
                                data={this.state.imgList}
                                maxImg={99}
                                isEditor={this.state.isEdit}
                              ></UploadImg>
                              {/* <input
                              ref="avatar-upload"
                              type="file"
                              id="avatar-btn"
                              name="avatar"
                              accept="jpg/png/gif"/> */}
                            </div>
                            <div className="upload-title">（最佳尺寸：100*100像素，仅支持 jpg、png、gif格式。）</div>
                            <div className='error-tip' style={{ display: this.state.imgerror ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.imgerror}</span></div>
                          </div>
                        </div>
                      </div>
                      <div className="err-row" ref="name-err-tip">
                        <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                        {this.state.usernameError}
                      </div>
                      <div className="form-row">
                        <label htmlFor="username">您的昵称：</label>
                        <input type="text" id="username" ref="username" defaultValue={this.state.name} />
                        <span className="error-tip" style={{ display: this.state.usernameError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.usernameError}</span></span>
                      </div>
                      <div className="form-row">
                        <label htmlFor="sex">您的性别：</label>
                        <div className="radios">
                          <span>
                            <a className={this.state.checked === 0 ? "sex checked" : "sex"} onClick={this.checkRadio.bind(this, 0)}></a>男
                        </span>
                          <span>
                            <a className={this.state.checked === 1 ? "sex checked" : "sex"} onClick={this.checkRadio.bind(this, 1)}></a>女
                        </span>
                        </div>
                      </div>
                      <div className="err-row" ref="email-err-tip">
                        <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                        {this.state.emailError}
                      </div>
                      <div className="form-row">
                        <label htmlFor="email">您的邮箱：</label>
                        <input type="text" id="email" ref="email" defaultValue={this.state.email} />
                        <span className="error-tip" style={{ display: this.state.emailError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.emailError}</span></span>
                      </div>
                      <div className="err-row" ref="phone-err-tip">
                        <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                        {this.state.phoneError}
                      </div>
                      <div className="form-row">
                        <label htmlFor="phone">您的手机号：</label>
                        <div className="phone-info">
                          {/* <span className="phone-number" ref="phone"></span> */}
                          <input type="text" className="phone-number" id="phone" ref="phone" onBlur={this.checkoutPhone} readOnly={true} defaultValue={this.state.morenPhone} />
                          <span className={this.state.disabled ? 'hide' : 'get-code'} onClick={this.showPhoneModal}>更改绑定手机号</span>
                          {/* <span className={this.state.disabled ? 'disable-send' : 'hide'}>重新发送({this.state.stateTime}s)</span> */}
                          <span className="error-tip" style={{ display: this.state.phoneError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.phoneError}</span></span>
                          {/* <a className='phone-replace'  onClick={this.showPhoneModal}>更换</a> */}
                        </div>
                      </div>
                      {/* <div  ref="code-check" className={this.state.codeBoxShow ? 'form-row' : 'hide'}>
                      <label htmlFor="code">验证码：</label> */}
                      {/*<input type="text" id="code" ref="code" style={{width: '224px'}} onBlur={this.validateCode}/> */}
                      {/*<input type="text" id="code" ref="code" style={{width: '224px'}} onBlur={this.validateCode}/>
                      <span className="error-tip" style={{display: this.state.codeError ? 'inline-block' : 'none'}}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.codeError}</span></span>
                    </div> */}
                      <div className="form-row">
                        <label htmlFor="service">当前服务：</label>
                        <div className="user-version">
                          <span className='free-version'>免费版</span>
                          {/* <span>升级联系：400-800-4647</span> */}
                        </div>
                      </div>
                      <div className="form-row btn-row">
                        <button type="button" className="confirm-btn" onClick={this.submitSetting}>保 存</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.renderModal()}
              {this.ChangePhone()}
            </div>
          </div>
          {/* <Footer></Footer> */}
          <PromptModal role="company" text={this.state.resText} id="prompt-modal" infoType={this.state.infoType}></PromptModal>
        </div>
      </section>

    );
  }

})