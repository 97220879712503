import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Radio } from 'antd'
import Button from 'antd/es/button';
import success from '../../assets/images/7DC91AF2-6243-4ca0-B0EE-813AF514ABE8.png'

import './PaySuccess.less'


class PaySuccess extends Component {
    state = {
    
    };
    componentDidMount() {
    }
    componentWillMount() {
    }

   

    render() {
        return (
            <div id="paysuccess">
                <div className="icon">
                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/authSuccess.png"></img>
                    
                </div>
                <p>开通成功!</p>
                
            </div>
        )
    }
}
export default PaySuccess;