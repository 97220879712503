
import BackHeader from '../../../common/BackHeader'
// import SideBar from '../../../common/SideBar'
import Footer from '../../../common/Footer'
import PromptModal from '../../../common/PromptModal'
import { Link } from 'react-router-dom';
import UploadImg from '../../../components/UploadImages/index'
import { Ajax } from '../../../utils/ajax';
import Menu from '../../../components/Menu/Menu'
require('./UserSetting.css')
// require('../../utils/upload');
var React = require("react");
var createReactClass = require('create-react-class');
var domain = ''
const $ = window.$;
const location = window.location
export default createReactClass({
  getInitialState: function () {
    return {
      activeTab: 1,
      credentialError: '',
      nameError: '',
      corporationError: '',
      addressError: '',
      phoneError: '',
      contactError: '',
      faxError: '',
      emailError: '',
      imgerror: '',
      industries: [],
      infoType: false,
      imgList: [],
      licence: 'http://lingdaima.dotlinkface.com/licence-default-bg.png'
    }
  },

  checkoutCredential: function () {
    var credentialNumber = $(this.refs['company-id']).val().trim();
    var compantIdErrTip = $(this.refs['company-id-err-tip']);
    if (!credentialNumber) {
      return true;
    }
    if (!/^[0-9]{18}$/.test(credentialNumber)) {
      this.setState({ credentialError: '证件号由18位数字组成' });
      compantIdErrTip.addClass('active');
    } else if (/^[0-9]{18}$/.test(credentialNumber)) {
      compantIdErrTip.removeClass('active');
      this.setState({ credentialError: '' });
      return true;
    }
    return false;
  },

  checkoutName: function () {
    var name = $(this.refs['company-name']).val().trim();
    var companyNameErrTip = $(this.refs['company-name-err-tip']);
    if (!name) {
      return true;
    }
    if (name.length < 2 || name.length > 16) {
      this.setState({ nameError: "公司名称长度在2,16之间" })
      companyNameErrTip.addClass('active');
    } else if (!/^[\w\u4e00-\u9fa5_]*$/.test(name)) {
      this.setState({ nameError: '只由数字、字母、汉字或者下划线组成' });
      companyNameErrTip.addClass('active');
    } else {
      this.setState({ nameError: "" });
      companyNameErrTip.removeClass('active');
      return true;
    }
    return false;
  },

  checkoutCorporation: function () {
    var corporation = $(this.refs['corporation']).val().trim();
    var companyCorporationErrTip = $(this.refs['company-corporation-err-tip'])
    if (!corporation) {
      return true;
    }
    if (corporation.length < 2 || corporation.length > 20) {
      this.setState({ corporationError: '法人名称由2到20个字符构成' });
      companyCorporationErrTip.addClass('active');
    } else if (!/^[\w\u4e00-\u9fa5_]*$/.test(corporation)) {
      this.setState({ corporationError: '只由数字、字母、汉字或者下划线组成' });
      companyCorporationErrTip.remveClass('active');
    } else {
      this.setState({ corporationError: "" });
      return true;
    }
    return false;
  },

  checkoutAddress: function () {
    var address = $(this.refs['company-address']).val().trim();
    var companyAddressErrTip = $(this.refs['company-address-err-tip']);
    if (!address) {
      return true;
    }
    console.log(address)
    if (address.length < 2 || address.length > 100) {
      this.setState({ addressError: '地址最少由2个字符构成' });
      companyAddressErrTip.addClass('active');
    } else if (!/^[\w\u4e00-\u9fa5_]*$/.test(address)) {
      this.setState({ addressError: '只由数字、字母、汉字或者下划线组成' });
      companyAddressErrTip.addClass('active');
    } else {
      this.setState({ addressError: "" });
      companyAddressErrTip.removeClass('active');
      return true;
    }
    return false;
  },

  checkoutEmail: function () {
    var tel = $(this.refs["company-email"]);
    var companyEmailErrTip = $(this.refs['company-email-err-tip'])
    if (!tel.val().trim()) {
      return true;
    }
    if (!/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(tel.val())) {
      this.setState({ emailError: "邮箱格式不正确" })
      companyEmailErrTip.addClass('active')
    } else if (/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(tel.val())) {
      this.setState({ emailError: "" });
      companyEmailErrTip.removeClass('active')
      return true;
    }
    return false;
  },

  checkoutContact: function () {
    var contact = $(this.refs['company-contact']).val().trim();
    var companyContactErrTip = $(this.refs['company-contact-err-tip']);
    if (!contact) {
      return true;
    }
    if (contact.length < 2 || contact.length > 20) {
      this.setState({ contactError: '合伙人名称由2到20个字符构成' });
      companyContactErrTip.addClass('active');
    } else if (!/^[\w\u4e00-\u9fa5_]*$/.test(contact)) {
      this.setState({ contactError: '只由数字、字母、汉字或者下划线组成' });
      companyContactErrTip.addClass('active');
    } else {
      this.setState({ contactError: "" });
      companyContactErrTip.removeClass('active');
      return true;
    }
    return false;
  },

  checkoutPhone: function () {
    var tel = $(this.refs['company-tel']);
    var companyPhoneErrTip = $(this.refs['company-phone-err-tip']);
    if (!tel.val().trim()) {
      return true;
    }
    if (!/^\d{3}-\d{8}|\d{4}-\{7,8}$/.test(tel.val())) {
      this.setState({ phoneError: "电话格式不正确" })
      companyPhoneErrTip.addClass('active')
    } else if (/^\d{3}-\d{8}|\d{4}-\{7,8}$/.test(tel.val())) {
      this.setState({ phoneError: "" })
      companyPhoneErrTip.removeClass('active')
      return true;
    }
    return false;
  },

  checkoutFax: function () {
    var fax = $(this.refs['company-fax']);
    var companyFaxErrTip = $(this.refs['company-fax-err-tip']);
    if (!fax) {
      return true;
    }
    if (!/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(fax.val())) {
      this.setState({ faxError: "传真格式不正确" })
      companyFaxErrTip.addClass('active')
    } else {
      this.setState({ faxError: "" })
      companyFaxErrTip.removeClass('active')
      return true;
    }
    return false;
  },

  isValid: function () {
    var credentialNumber = this.checkoutCredential();
    var name = this.checkoutName();
    var corporation = this.checkoutCorporation();
    var address = this.checkoutAddress();
    var email = this.checkoutEmail();
    var contact = this.checkoutContact();
    // var tel = this.checkoutPhone();
    // var industry = $(this.refs['company-industry']).text();
    var licence = $(this.refs['licence-preview']).attr('src');
    console.log(credentialNumber, name, corporation, address, email, contact, licence)
    if (credentialNumber && name && corporation && address && email && contact && licence) {
      return true;
    }
    return false;
  },

  componentDidMount: function () {
    var credentialNumber = $(this.refs['company-id']);
    var name = $(this.refs['company-name']);
    var corporation = $(this.refs['corporation']);
    var address = $(this.refs['company-address']);
    var email = $(this.refs['company-email']);
    var contact = $(this.refs['company-contact']);
    var tel = $(this.refs['company-tel']);
    var fax = $(this.refs['company-fax']);
    var industry = $(this.refs['company-industry']);
    var licence = $(this.refs['licence-preview']);
    var licenceSpan = $(this.refs['phone-company-industry'])

    this.getData()

    // $.get(`${domain}/json/template/tmplclass/alltmplindustry`, function (result) {
    //   if (result.success) {
    //     var data = result.date;
    //     var list = [];
    //     data.forEach(function (item) {
    //       list.push(item.industry);
    //     });
    //     this.setState({ industries: list });
    //   }
    // }.bind(this));     
    // $.get('/json/my/companyInfo', function (result) {
    //   if (result.success) {
    //     var data = result.data;
    //     this.setState({initData: data});
    //     credentialNumber.val(data.credentialNumber);
    //     name.val(data.name);
    //     corporation.val(data.corporation);
    //     address.val(data.address);
    //     email.val(data.email);
    //     contact.val(data.contact);
    //     tel.val(data.tel);
    //     fax.val(data.fax);
    //     if (data.tmplindustry) {
    //       industry.text(!data.tmplindustry.industry ? '请选择' : data.tmplindustry.industry);
    //       licenceSpan.html(!data.tmplindustry.industry ? '' : data.tmplindustry.industry)
    //     }
    //     licence.attr({
    //       src: (data.licence || 'http://lingdaima.dotlinkface.com/licence-default-bg.png')
    //     });
    //   } else {
    //     this.setState({
    //       credentialError: "请填写有效的企业证件号"
    //     })
    //   }
    // }.bind(this));
    this.uploadLicence();
    credentialNumber.on('blur', function () {
      this.checkoutCredential();
    }.bind(this));
    name.on('blur', function () {
      this.checkoutName();
    }.bind(this));
    corporation.on('blur', function () {
      this.checkoutCorporation();
    }.bind(this));
    address.on('blur', function () {
      this.checkoutAddress();
    }.bind(this));
    email.on('blur', function () {
      this.checkoutEmail();
    }.bind(this));
    contact.on('blur', function () {
      this.checkoutContact();
    }.bind(this));
    // tel.on('blur', function () {
    //   this.checkoutPhone();
    // }.bind(this));
    // fax.on('blur', function () {
    //   this.checkoutFax();
    // }.bind(this));
  },

  getData: function () {
    Ajax.GET('/ldmshop/userCompany/get')
      .then(res => {
        if (res && res.code == 0) {
          console.log(res)
          let imgList = this.state.imgList
          imgList[0] = res.data.businessLicense
          this.setState({
            imgList,
            address: res.data.address,
            businessCertificate: res.data.businessCertificate,
            companyEmail: res.data.companyEmail,
            companyName: res.data.companyName,
            companyPhone: res.data.companyPhone,
            contactPerson: res.data.contactPerson,
            corporate: res.data.corporate,
            fax: res.data.fax,

          })
        } else {

        }

      })
      .catch(error => {
        console.log(error)
      })
  },

  submitCompany: function (e) {
    e.preventDefault();
    var credentialNumber = $(this.refs['company-id']).val().trim();
    var name = $(this.refs['company-name']).val().trim();
    var corporation = $(this.refs['corporation']).val().trim();
    var address = $(this.refs['company-address']).val().trim();
    var email = $(this.refs['company-email']).val().trim();
    var contact = $(this.refs['company-contact']).val().trim();
    var tel = $(this.refs['company-tel']).val().trim();
    var fax = $(this.refs['company-fax']).val().trim();
    var industry = $(this.refs['company-industry']).text();
    console.log('______1')
    if (!this.isValid()) {
      return;
    }
    console.log('______2')
    var data = {};

    data.businessCertificate = credentialNumber;


    data.companyName = name;


    data.corporate = corporation;


    data.address = address;


    data.companyEmail = email;


    data.contactPerson = contact;


    data.companyPhone = tel;


    data.fax = fax;





    data.businessLicense = this.state.imgList[0];



    Ajax.POST('/ldmshop/userCompany/updateUserCompanyVO', data)
      .then(res => {
        if (res && res.code == 0) {
          this.setState({ resText: '修改成功', infoType: true });
          $('#prompt-modal').modal('show');
        } else {
          this.setState({ resText: '操作失败', infoType: false });
          $('#prompt-modal').modal('show');
        }

      })
      .catch(error => {
        console.log(error)
      })


    // $.post('/json/my/companyInfo', data, function (result) {
    //   console.log(result.success)
    //   if (result.success) {
    //     this.setState({ resText: '修改成功', infoType: true });
    //     $('#prompt-modal').modal('show');
    //   } else {
    //     this.setState({ resText: '操作失败', infoType: false });
    //     $('#prompt-modal').modal('show');
    //   }
    // }.bind(this));
  },

  selectItem: function (e) {
    e.preventDefault();
    var item = $(e.target);
    var industry = $(this.refs['company-industry']);
    var industryPhone = $(this.refs['phone-company-industry']);
    industry.text(item.text());
    industryPhone.text(item.text().substr(0, 4));
  },

  renderItem: function () {
    if (this.state.industries.length === 0) {
      return;
    }
    var list = [];
    list = this.state.industries.map(function (item, index) {
      return <li key={index}>
        <a href="#">{item}</a>
      </li>;
    }.bind(this));
    return list;
  },

  uploadLicence() {
    // var self = this;
    //   let url =`${domain}/json/my/licenceUpload`;
    //   var $input = $("#upload-lic").html5_upload({
    //     url: url,
    //     sendBoundary: window.FormData || $.browser.mozilla,
    //     onStart: function(event, total) {
    //       var imgerror = ''
    //       let iMaxFilesize = 3 * 1024 * 1024; //3MB
    //       var oFile = document.getElementById('upload-lic').files[0];      
    //       let rFilter = /^(image\/gif|image\/jpeg|image\/png)$/i;      
    //       if (!rFilter.test(oFile.type)) {
    //           document.getElementById('upload-lic').value='';
    //           self.setState({
    //               imgerror : '图片类型出错'
    //           })
    //           return false;
    //       }             
    //       if (oFile.size > iMaxFilesize) {
    //           document.getElementById('upload-lic').value='';
    //           self.setState({
    //               imgerror : '图片超过大小限定'
    //           })
    //           return false;
    //       }              
    //       return true;
    //     },
    //     fieldName:"licence",
    //     onProgress: function(event, progress, name, number, total) {
    //         console.log(progress, number);
    //     },
    //     setName: function(text) {
    //         // $("#progress_report_name").text(text);
    //     },
    //     setStatus: function(text) {
    //         // $("#progress_report_status").text(text);
    //     },
    //     setProgress: function(val) {
    //         // $("#progress_report_bar").css('width', Math.ceil(val*100)+"%");
    //     },
    //     onFinishOne: function(event, response, name, number, total) {
    //       var result = JSON.parse(response);
    //       console.log(result);
    //       if(result.success){
    //         self.setState({
    //           imgerror : ''
    //         })
    //         $('#licence-preview').attr({ src: result.licence });
    //       }
    //     },
    //     onError: function(event, name, error) {
    //       alert('error while uploading file ' + name);
    //       console.log(error);
    //     }
    // });
  },
  uploadImagesSuccess: function (data) {
    let arr = [];
    let imgList = this.state.imgList
    if (imgList.length == 0) {
      data.forEach(item => {
        arr.push(item.response || item.url);
      });
      this.setState({
        imgList: arr,
      }, () => {
        console.log(this.state.imgList)
      });
    }
    else if (imgList.length == 1) {
      data.forEach(item => {
        arr.splice(0, 1, item.response || item.url)
      })
      this.setState({
        imgList: arr,
      }, () => {
        console.log(this.state.imgList)
      });
    }

  },

  render: function () {
    return (
      <section>
        <Menu ></Menu>

        <div className="my" style={{ width: '1000px' }}>
          {/* <BackHeader role="company"></BackHeader> */}
          <div className="company-content">
            {/* <SideBar activeState={2} role={0}></SideBar> */}
            <div id="user-setting" className="container" >
              <div className="user-settings-wrapper row" >
                <div className="nav-wrapper col-md-12">
                  <ul className="col-md-12 tab-list">
                    <li
                      className={this.state.activeTab === 0
                        ? 'activeclass'
                        : ''}>
                      <Link ref="userSetting" to="/admin/userSetting">基本资料</Link>
                    </li>
                    <li
                      className={this.state.activeTab === 1
                        ? 'activeclass'
                        : ''}>
                      <Link ref="companyInfo" to="/admin/companyInfo">公司信息</Link>
                    </li>
                    <li
                      className={this.state.activeTab === 2
                        ? 'activeclass'
                        : ''}>
                      <Link ref="modifyPassword" to="/admin/modifyPassword">修改密码</Link>
                    </li>
                  </ul>
                </div>
                <div className="company-info col-md-12" id="companyInfo">
                  <div className="form-row">
                    <label className="licence-label">营业执照：</label>
                    <div className="licence-wrapper">
                      <div className="upload-title-mobile">
                        （最大3M,仅支持jpg、png、gif格式，复印件需要盖章）
                    </div>
                      <div className="licence" id="licence" title="点击上传营业执照图片">
                        <div className='licence-img'>
                          <label htmlFor="upload-lic">
                            <img src={this.state.imgList.length == 0 ? this.state.licence : this.state.imgList[0]} alt="" ref="licence-preview" id='licence-preview' />
                          </label>
                        </div>

                      </div>
                      <div className="licence-upload">
                        {/* <div className="upload">
                        <input type="file" id="upload-lic" name="licence" />
                      </div> */}
                        <span>（最大3M，仅支持 jpg、png、gif格式，复印件需要盖章）</span>
                        <div className="upload-title">
                          {/* <label htmlFor="upload-lic" className="company-upload-label">上传图片</label> */}
                          <UploadImg
                            uploadSuccess={this.uploadImagesSuccess}
                            data={this.state.imgList}
                            maxImg={99}
                            isEditor={this.state.isEdit}
                          ></UploadImg>

                        </div>
                      </div>
                    </div>
                    <span className="err-msg" style={{ display: this.state.imgerror ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.imgerror}</span></span>
                  </div>
                  <div className="err-row" ref="company-id-err-tip">
                    <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                    {this.state.credentialError}
                  </div>
                  <div className="form-row">
                    <label htmlFor="company-id">企业/机构证件号：</label>
                    <input type="text" id="company-id" ref="company-id" className="long-input" defaultValue={this.state.businessCertificate} />
                    <span className="error-tip" style={{ display: this.state.credentialError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.credentialError}</span></span>
                  </div>
                  <div className="err-row" ref="company-name-err-tip">
                    <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                    {this.state.nameError}
                  </div>
                  <div className="form-row">
                    <label htmlFor="company-name">公司名称：</label>
                    <input type="text" id="company-name" ref="company-name" className="long-input" defaultValue={this.state.companyName} />
                    <span className="error-tip" style={{ display: this.state.nameError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.nameError}</span></span>
                  </div>
                  <div className="err-row" ref="company-corporation-err-tip">
                    <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                    {this.state.corporationError}
                  </div>
                  <div className="form-row">
                    <label htmlFor="corporation">公司法人：</label>
                    <input type="text" id="corporation" ref="corporation" defaultValue={this.state.corporate} />
                    <span className="error-tip" style={{ display: this.state.corporationError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.corporationError}</span></span>
                  </div>
                  <div className="err-row" ref="company-address-err-tip">
                    <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                    {this.state.addressError}
                  </div>
                  <div className="form-row">
                    <label htmlFor="company-address">公司地址：</label>
                    <input type="text" id="company-address" ref="company-address" className="long-input" defaultValue={this.state.address} />
                    <span className="error-tip" style={{ display: this.state.addressError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.addressError}</span></span>
                  </div>
                  <div className="err-row" ref="company-email-err-tip">
                    <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                    {this.state.emailError}
                  </div>
                  <div className="form-row">
                    <label htmlFor="company-email">公司邮箱：</label>
                    <input type="text" id="company-email" ref="company-email" defaultValue={this.state.companyEmail} />
                    <span className="error-tip" style={{ display: this.state.emailError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.emailError}</span></span>
                  </div>
                  <div className="err-row" ref="company-contact-err-tip">
                    <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                    {this.state.contactError}
                  </div>
                  <div className="form-row">
                    <label htmlFor="company-contact">联系人：</label>
                    <input type="text" id="company-contact" ref="company-contact" defaultValue={this.state.contactPerson} />
                    <span className="error-tip" style={{ display: this.state.contactError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.contactError}</span></span>
                  </div>
                  <div className="err-row" ref="company-phone-err-tip">
                    <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                    {this.state.phoneError}
                  </div>
                  <div className="form-row">
                    <label htmlFor="company-tel">公司电话：</label>
                    <input type="text" id="company-tel" ref="company-tel" defaultValue={this.state.companyPhone} />
                    <span className="error-tip" style={{ display: this.state.phoneError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.phoneError}</span></span>
                  </div>
                  <div className="err-row" ref="company-fax-err-tip">
                    <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                    {this.state.faxError}
                  </div>
                  <div className="form-row">
                    <label htmlFor="company-fax">公司传真：</label>
                    <input type="text" id="company-fax" ref="company-fax" defaultValue={this.state.fax} />
                    <span className="error-tip" style={{ display: this.state.faxError ? 'inline-block' : 'none' }}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" /><span>{this.state.faxError}</span></span>
                  </div>
                  {/* <div className="form-row btn-row">
                  <label htmlFor="industry">所属行业：</label>
                  <span ref="phone-company-industry" className="phone-company-industry"></span>
                  <div className="btn-group dropup">
                    <button
                      ref="drop-btn"
                      type="button"
                      className="industry-btn"
                      id="industry-btn"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true">
                      <span ref="company-industry"><span style={{color: '#999', marginLeft: 0}}>请选择</span></span>
                      <span className="glyphicon glyphicon-menu-right" style={{float: 'right', marginRight: '15px', marginTop: '3px'}}></span>
                    </button>
                    <button
                      ref="drop-btn"
                      type="button"
                      className="phone-industry-btn"
                      id="phone-industry-btn"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true">
                      <span className="glyphicon glyphicon-menu-right"></span>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="industry-btn" onClick={this.selectItem}>
                      {this.renderItem()}
                    </ul>
                  </div>
                </div> */}
                  <div className="form-row btn-row confirm-row">
                    <button type="button" className="confirm-btn " onClick={this.submitCompany} style={{ marginLeft: '144px' }}>保 存</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer></Footer> */}
          <PromptModal role="company" id="prompt-modal" text={this.state.resText} infoType={this.state.infoType}></PromptModal>
        </div>
      </section>

    )
  }
})