
import BackHeader from '../../../common/BackHeader'
// import SideBar from '../../../common/SideBar'

import PromptModal from '../../../common/PromptModal'
import {Link} from 'react-router-dom';
import Menu from '../../../components/Menu/Menu'

import {Ajax} from '../../../utils/ajax'
import JSEncrypt from 'jsencrypt';
require('./UserSetting.css')
var React = require("react");var createReactClass = require('create-react-class');
const $ = window.$;
const location = window.location
export default createReactClass({
  getInitialState: function () {
    return {
      activeTab: 2,
      oldPasswordError: "",
      newPasswordError: "",
      rePasswordError: "",
      resText: '',
      infoType: false
    }
  },

  componentDidMount: function(){
    Ajax.GET(`/ldmshop/admin/getKey`)
    .then(res => {
        console.log(res)
        this.setState({
            publicKey: res.data.key
        })
    })
    .catch(error => {

    })
  },

  checkoutPassword: function () {
    var oldPwd = $(this.refs["old-pwd"]);
    var newPwd = $(this.refs["new-pwd"]);
    var rePwd = $(this.refs["re-pwd"]);
    var oldErrtip = $(this.refs["old-pwd-tip"]);
    var newErrtip = $(this.refs["new-pwd-tip"]);
    var repeatErrtip = $(this.refs["repeat-pwd-tip"]);
    if (!oldPwd.val().trim()) {
      this.setState({oldPasswordError: "密码不能为空"})
      oldErrtip.addClass('active')
    } else if(!newPwd.val().trim()) {
      this.setState({newPasswordError: "密码不能为空"})
      newErrtip.addClass('active')
    } else if(!rePwd.val().trim()) {
      this.setState({rePasswordError: "密码不能为空"})
      repeatErrtip.addClass('active')
    // } else if (!/^\w{8,16}$/.test(newPwd.val())) {
     } else if (!/^[a-zA-Z0-9\W_]{8,16}$/.test(newPwd.val())) {
      this.setState({newPasswordError: "密码格式不正确，密码由8-16位的数字/字母/英文符号组成"})
      oldErrtip.addClass('active')
    } else if (newPwd.val() !== rePwd.val()) {
      this.setState({rePasswordError: "两次密码输入不正确"})
    } else {
      this.setState({oldPasswordError: "", newPasswordError: "", rePasswordError: "" })
      oldErrtip.removeClass('active')
      newErrtip.removeClass('active')
      repeatErrtip.removeClass('active')
      return true;
    }
    return false;
  },

  sendPwd: function (e) { 
    e.preventDefault();
    var oldPwd = $(this.refs['old-pwd']).val();
    var newPwd = $(this.refs['new-pwd']).val(); 
    var oldErrtip = $(this.refs["old-pwd-tip"]);   
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(this.state.publicKey);
    let newpasswordEncrypt = encrypt.encrypt(newPwd);
    let oldpasswordEncrypt = encrypt.encrypt(oldPwd);
    if (!this.checkoutPassword()) {
      return ;
    }   


    Ajax.POST('/ldmshop/admin/modifyPassword', { newPassword:newpasswordEncrypt,originalPassword:oldpasswordEncrypt,repeatPassword:newpasswordEncrypt })
            .then(res => {
              if (res && res.code == 0) {
                this.setState({ resText: '修改成功', infoType: true });
                $('#prompt-modal').modal('show');
                // location.href = '/my/userSetting';
              } else {
                console.log(res);
                this.setState({ oldPasswordError: res.errmsg });
                oldErrtip.addClass('active')
              }
                
            })
            .catch(error => {
              this.setState({ oldPasswordError: error});
                oldErrtip.addClass('active')
                console.log(error)
            })


    // $.post('/json/my/modify', {  
    //   oldPwd: oldPwd,
    //   newPwd: newPwd
    // }, function (res) {
    //   if (res.success) {
    //     this.setState({ resText: '修改成功', infoType: true });
    //     $('#prompt-modal').modal('show');
    //     // location.href = '/my/userSetting';
    //   } else {
    //     this.setState({ oldPasswordError: res.errfor });
    //     oldErrtip.addClass('active')
    //   }
    // }.bind(this));
  },


  render: function () {
    return (
      <section>
        <Menu ></Menu>
        <div className="my" style={{width:'1000px'}}>
        {/* <BackHeader role="company"></BackHeader> */}
        <div className ="company-content">
          {/* <SideBar activeState={2} role={0}></SideBar> */} 
          <div id="user-setting" className="containerr">
            <div className="user-settings-wrapper row">
              <div className="nav-wrapper col-md-12">
              <ul className="col-md-12 tab-list">
                  <li
                    className={this.state.activeTab === 0
                    ? 'activeclass'
                    : ''}>
                      <Link ref="userSetting" to="/admin/userSetting">基本资料</Link>
                  </li>
                  <li
                    className={this.state.activeTab === 1
                    ? 'activeclass'
                    : ''}>
               
                      <Link ref="companyInfo" to="/admin/companyInfo">公司信息</Link>
                  </li>
                  <li
                    className={this.state.activeTab === 2
                    ? 'activeclass'
                    : ''}>
                      <Link ref="modifyPassword" to="/admin/modifyPassword">修改密码</Link>
                  </li>
                </ul>
              </div>
              <div className="modify col-md-12" id="modifyPwd">
                <div className="err-row" ref="old-pwd-tip">
                  <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                  {this.state.oldPasswordError}
                </div>
                <div className="form-row">
                  <label htmlFor="old-pwd">旧密码：</label>
                  <input type="password" id="old-pwd" ref="old-pwd"/>
                  <span className="error-tip" style={{display: this.state.oldPasswordError ? 'inline-block' : 'none'}}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png"/><span>{this.state.oldPasswordError}</span></span>
                </div>
                <div className="err-row" ref="new-pwd-tip">
                  <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                  {this.state.newPasswordError}
                </div>
                <div className="form-row">
                  <label htmlFor="new-pwd">设置新密码：</label>
                  <input type="password" id="new-pwd" ref="new-pwd"/>
                  <span className="error-tip" style={{display: this.state.newPasswordError ? 'inline-block' : 'none'}}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png"/><span>{this.state.newPasswordError}</span></span>
                </div>
                <div className="err-row" ref="repeat-pwd-tip">
                  <img src="http://lingdaima.dotlinkface.com/error-tip-icon.png" />
                  {this.state.rePasswordError}
                </div>
                <div className="form-row">
                  <label htmlFor="re-pwd">确认新密码：</label>
                  <input type="password" id="re-pwd" ref="re-pwd"/>
                  <span className="error-tip" style={{display: this.state.rePasswordError ? 'inline-block' : 'none'}}><img src="http://lingdaima.dotlinkface.com/error-tip-icon.png"/><span>{this.state.rePasswordError}</span></span>
                </div>
                <div  className="form-row btn-row confirm-row">
                  <button className="confirm-btn" onClick={this.sendPwd}>保 存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer></Footer> */}
        <PromptModal role="company" text={this.state.resText} infoType={this.state.infoType}></PromptModal>
      </div>
      </section>
      
    )
  }
});