import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import icon from '../../../assets/images/xcx.jpg'
import './Release.less'
import Menu from '../../../components/Menu/Menu'
import * as Admin from '../store/admin';
import http from '../../../http/http';
import { Ajax } from '../../../utils/ajax'
import { withRouter } from 'react-router-dom'
import { message } from 'antd'
class Release extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isRelease: false,
            status: {}
        }
    }
    componentDidMount() {
        this.getData();
        this.getXCXstate();
    }
    componentWillMount() {
    }
    getXCXstate(isRelease) {
        Ajax.GET(`/ldmshop/code/auditStatus?authAppId=${this.props.adminReducer.shopData.mainAppId}`)
            .then(res => {
                console.log(res);
                if (res.code == -1) {

                } else if (res.code == 0) {
                    this.setState({
                        status: res.data
                    })
                }
            })
    }
    getData() {
        Ajax.GET(`/ldmshop/mini/data/getWeChatMiniInfo?authAppId=${this.props.adminReducer.shopData.mainAppId}`)
            .then(res => {
                console.log(res);
                if (res.code == 0) {
                    this.props.actions.setShopInfo(res.data);
                    this.getWeixinImage(res.data.qrCodeUrl);
                } else {
                    message.error(res.errmsg)
                }
            })
    }
    getWeixinImage(url) {
        window.WxImgTemp = '';
        let time = new Date().getTime(),
            frameid = 'wxImg_' + time;
        console.log(url)
        window.WxImgTemp = '<img id="img" style="width:100%" src=\'' + url + '?' + time + '\' />\
        <script>window.onload = function() {\
        parent.document.getElementById(\'' + frameid + '\').height = document.getElementById(\'img\').height+\'px\'; \
        }</script>';
        this.refs.iframe.src = "javascript:parent.WxImgTemp";
    }
    goToInformation() {
        this.props.history.push("/Information")
    }
    go() {
        this.props.history.push({ pathname: "/admin/Submission" })
        sessionStorage.setItem("key", 12);
    }
    getRelease() {
        Ajax.POST(`/ldmshop/code/publish?authAppId=${this.props.adminReducer.shopData.mainAppId}`)
            .then(res => {
                if (res.code == 0) {
                    this.getData();
                }
            })
            .catch(res =>{
                message.error(res)
            })
    }
  
    render() {
        const { miniNickName, miniHeadImg, signature, verifyTypeInfoStr, miniStatus } = this.props.adminReducer.shopInfo;
        console.log(this.state.isRelease);
        return <section>
            <Menu ></Menu>

            <div className="ReleasePages">
                <div className="title">
                微信小程序配置
            </div>
                <div className="tabs">
                    <span >
                        小程序授权
                </span>
                    <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span>
                    小程序预览
                </span>
                    <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span>
                        小程序提交审核
                </span>
                    <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span className="active">
                        小程序完成发布
                </span>
                </div>
                <div className="content">
                    <div className="tips">{this.state.status.status == 0? miniStatus && miniStatus == 6 ?"你的小程序已经发布了，可扫小程序码查看": '恭喜你，小程序审核通过，可以发布了!' : "您暂无要发布的小程序"}</div>
                    <div className="list">
                        <span>小程序名称：</span>
                        <span>{miniNickName}</span>
                    </div>
                    <div className="list">
                        <span>小程序图标：</span>
                        <img className="logo" src={miniHeadImg}></img>
                    </div>
                    <div className="list">
                        <span>小程序介绍：</span>
                        <span>{signature}</span>
                    </div>
                    <div className="list">
                        <span>小程序认证：</span>
                        <span>{verifyTypeInfoStr}</span>
                    </div>
                    <div className="list">
                        <span>小程序码：</span>
                        {/* <img className="share" src={qrCodeUrl}></img> */}
                        <iframe ref="iframe" className="share" >

                        </iframe>
                    </div>
                    {this.state.status.status == 0 ? (
                        <div className="buttonList">
                            {console.log(miniStatus)}
                            {miniStatus && miniStatus != 6 ? (<div className="button" onClick={this.getRelease.bind(this)}>
                                发布<span className="iconfontAdmin iconshuangjiantou-copy"></span>
                            </div>) : null}
                            <div className="button" onClick={this.go.bind(this)}>
                                重新审核<span className="iconfontAdmin iconshuangjiantou-copy"></span>
                            </div>
                        </div>
                    ) : null}

                </div>
            </div>
        </section>
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Release));