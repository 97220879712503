import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Radio } from 'antd'
import Button from 'antd/es/button';
import Menu from 'antd/es/menu'
import Input from 'antd/es/input'
import alipay from '../../assets/images/439A574B-5DA3-4299-8F9C-31CE68865DCC.png'
import wechatpay from '../../assets/images/2F378A5A-E6F8-40cc-8745-9B29E1574799.png'

import './OrderSure.less'
import Header from '../../common/Header'

class OrderSure extends Component {
    state = {
        year:1,
    };
    componentDidMount() {
        console.log(this.props.location.state.type)//val值
        let type=this.props.location.state.type
        this.setState({
            type
        })
        if(type == 1){
            this.setState({
                price:1888
            })
        }
        else if(type ==2){
            this.setState({
                price:2888
            })
        }
        else{
            this.setState({
                price:4888
            })
        }
    }
    componentWillMount() {
    }

    changeYear(e){
        console.log(e.target.value)
        this.setState({
            year:e.target.value
        })
    }

    render() {
        return (
            <div id="ordersure">
                <Header type="home" active="home" cur="home"></Header>
                <p className="title">小程序订单确认</p>
                <div className="order-box">
                    <p>电商小程序通用版</p>
                    <div className="chooseyear">
                        <p>选择服务年限：</p>
                        <Radio.Group defaultValue="1" buttonStyle="solid" onChange={this.changeYear.bind(this)}>
                            <Radio.Button value="1">1年</Radio.Button>
                            <Radio.Button value="2">2年</Radio.Button>
                            <Radio.Button value="3">3年</Radio.Button>
                            <Radio.Button value="4">4年</Radio.Button>
                        </Radio.Group>
                    </div>
                    <p>到期时间：2019-12-12</p>
                    <p>单价：<span style={{color:'red'}}>￥<span>{this.state.price}</span>元/年</span></p>
                    <p>费用合计：￥{this.state.year * this.state.price}元</p>
                    <div className="chooseyear">
                        <p>选择支付方法:</p>
                        <Radio.Group defaultValue="1" buttonStyle="outline" size="large" style={{marginLeft:'10px'}}>
                            <Radio.Button value="1"><div style={{width:'168px', height:'67px'}}><img src={alipay} style={{width:'30px',height:'30px'}}></img>支付宝</div></Radio.Button>
                            <Radio.Button value="2"><div style={{width:'168px', height:'67px'}}><img src={wechatpay} style={{width:'30px',height:'30px'}}></img>微信</div></Radio.Button>
                        </Radio.Group>
                    </div>
                    <Button type="primary" style={{marginLeft:'15%',width:'166px',height:'50px',marginTop:'90px'}} >确认</Button>
                </div>
            </div>
        )
    }
}
export default OrderSure;