import Header from '../../common/Header'
import Footer from '../../common/Footer'

import './index.css';
const $ = window.$;
var React = require("react");

var createReactClass = require('create-react-class');


export default createReactClass({

    componentDidMount: function () {
        $('.web').scrollTop(0);
    },
    componentWillUnmount: function () {

    },
    openkefu(){
        var kefu101 = "http://wpa.qq.com/msgrd?v=3&uin=2435670291&site=oicqzone.com&menu=yes";
        var kefu102 = "mqqwpa://im/chat?chat_type=wpa&uin=2435670291&version=1&src_type=web&web_src=oicqzone.com";
        if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          window.open(kefu102) ;
        } else {
          window.open(kefu101) ;
        }
      },
    
    render: function () {
        // var id = this.props.params.id
        return (
            <div id="aboutus-Page" className='aboutus-page'>
                <Header type="aboutus" active="aboutus" cur="aboutus"></Header>
                <div  id='aboutus' className='container-fluid  aboutus-container'>
                         <div className='container'>
                            <div className="row">
                                <div className='abouts-title  col-md-12'>关于我们</div>
                                <div className='abouts-body col-md-12 col-xs-12'>

                                    <div className='abouts-1 col-md-12 clearfix'>
                                    <div className="row">
                                            <div className='abouts-1-txt-1 col-md-6 col-xs-12'>
                                                {/*有坑   下面两个div换下位置就出毛病  说服务器端渲染和客户端不一致*/}
                                                <div className='abouts-1-txt-header'>茜芙（杭州）生物科技有限公司</div>
                                                <div className='abouts-2-txt-header'>sifshop.cn</div>
                                                <div className='col-md-6 col-xs-12 abouts-1-img-1' id='abouts-phone'>
                                                    <div className='ruabannar'></div>
                                                </div>
                                                <p className='aboutus-phone-martop'>
                                                    茜芙（杭州）生物科技有限公司（简称茜芙科技）于2018年初创建，<br/>
                                                    创始团队来自阿里巴巴，<br/>
                                                    是一家专注为中小企业提供互联网品牌全方位营销服务的创新型企业，<br/>
                                                    致力成为“企业成长的助推器”。
                                                </p>
                                                <p>
                                                    公司主要的产品是“品牌智能建站平台”和社交电商小程序平台，<br/>
                                                    结合行业需求和用户交通点，为企业/商家提供平台级SaaS服务，<br/>
                                                    搭配成熟的运营工具，有效为企业/商家提升品牌营销核心竞争力。<br/>
                                               
                                                </p>
                                                <p>
                                                    未来，茜芙科技将打造一个完整的互联网品牌营销闭环，<br/>
                                                    为企业提供流量导入、营销工具、分发渠道、用户转化等全方位互联网品牌营销解决方案，<br/>
                                                    无论企业是否具有互联网运营经验，茜芙科技都将助力企业可以成为行业中的品牌佼佼者。
                                                </p>
                                            </div>
                                            <div className='col-md-6 col-xs-12 abouts-1-img-1' id='abouts-pc'>
                                                {/* <img src='https://dotlinkface-lingdaima.oss-cn-hangzhou.aliyuncs.com/aboutus/aboutuspic1.png' /> */}
                                                {/*坑2  不能用img标签  只能用div加背景图  换成img会导致服务器和客户端渲染冲突*/}
                                                <div className='ruabannar'></div>
                                                {/*<div style={{"height":"700px","width":"700px","background":"url('https://dotlinkface-lingdaima.oss-cn-hangzhou.aliyuncs.com/aboutus/aboutuspic1.png')"}}></div>*/}
                                            </div>

                                    </div>
                                </div>
                                <div className='aboutus-2 col-md-12 clearfix'>
                                    {/* <div className='aboutus-2-txt-1'>PLATFORM FEATURES</div> */}
                                    {/* <div className='aboutus-2-txt-2'>品牌智能建站平台特点</div> */}
                                    <div className='aboutus-2-txt-3'>社交电商小程序平台特点</div>
                                    <div className='aboutus-2-list col-md-12 padding0'>
                                        <div className='aboutus-2-item col-md-3 col-xs-12 '>
                                            <div className="about-rua-img-1"></div>
                                            <div className='item-1'>快</div>
                                            <div className='item-2'>1分钟可预览</div>
                                            <div className='item-3'>你只需输入店铺名称，剩下的交给平台，1分钟即可预览到你的小程序店铺效果，让你体验零门槛接入社交电商。</div>
                                        </div>
                                        
                                        <div className='aboutus-2-item col-md-3 col-xs-12 '>
                                            <div className="about-rua-img-2"></div>
                                            <div className='item-1'>省</div>
                                            <div className='item-2'>1人就能运营商城</div>
                                            <div className='item-3'>不需要庞大的运营团队，只需要1个人管理好商品及售后，其它的就交给完善的平台吧！</div>
                                        </div>
                                        <div className='aboutus-2-item col-md-3 col-xs-12 '>
                                            <div className="about-rua-img-3"></div>
                                            <div className='item-1'>VIP</div>
                                            <div className='item-2'>VIP为你带货</div>
                                            <div className='item-3'>只需发挥你的人脉资源，让更多人成为VIP，无数的会员将自动为你带货。</div>           
                                        </div>
                                        <div className='aboutus-2-item col-md-3 col-xs-12 '>
                                            <div className="about-rua-img-4"></div>
                                            <div className='item-1'>销</div>
                                            <div className='item-2'>二级分销裂变传播</div>
                                            <div className='item-3'>二级分销体系，根据贡献程度自动成长，让每个进来的人都能赚钱，分销订单越多等级越高，等级越高赚得越多。</div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                         </div>

                         <div className='abouts-map-bg'>
                            <div className='container'>
                                <div className='row'>
                                    <div  className='abouts-body-2 col-md-12 col-xs-12 padding0'>
                                        <div className='abouts-body-left col-md-8 col-xs-12 padding0'>
                                            <img src='https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/position.jpg' />
                                            {/* <div className='abouts-map'></div> */}
                                        </div>
                                        <div className='abouts-body-right col-md-4 col-xs-12'>
                                            <div className="abouts-body-btm">
                                                <div>CONTACT US</div>
                                                <div>联系我们</div>
                                                <div>地址：杭州市余杭区良睦路1399号梦想小镇互联网村2号楼</div>
                                                <div>电子邮箱：programmerxwb@163.com</div>
                                                <div>业务合作：18742053597</div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                         </div>
                </div>
                <Footer cur="aboutus"> </Footer>
            </div>
        );
    }
});
