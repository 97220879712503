import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Cascader, Input } from 'antd';
import './Submission.less'
import Tips from '../../../components/Tips/Tips'
import http from '../../../http/http'
import { Ajax } from '../../../utils/ajax'
import { withRouter } from 'react-router-dom'
import Menu from '../../../components/Menu/Menu'
import { message,Modal } from 'antd'
import secret from '../../../utils/secret';
class Submission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            isSubmit: false,
            isShowTips: false,
            options: [],
            status: {},
            qrCodeUrl: "",
            visible:false
        }
    }
    componentDidMount() {
        // const isRelease = this.props&&this.props.location&&this.props.location.state&&this.props.location.state.isRelease;
  
        this.getCategory();
        this.getXCXstate();

        if (!this.props.adminReducer.shopData.mainAppId) {
            this.setState({
                isShowTips: true
            })
        }
        else {
            this.setState({
                mainAppId: this.props.adminReducer.shopData.mainAppId
            })
        }

    }
    getXCXstate(isRelease) {
        Ajax.GET(`/ldmshop/code/auditStatus?authAppId=${this.props.adminReducer.shopData.mainAppId}`)
            .then(res => {
                console.log(res);
                if (res.code == -1) {
                    this.setState({
                        isSubmit: false,
                    })
                } else if (res.code == 0) {
                    this.setState({
                        status: res.data
                    })
                    if (res.data.status == 0) {
        
                        // if(!isRelease){
                        // this.props.history.push("/admin/Release")
                        // }
                    } else if (res.data.status == 1) {

                    } else if (res.data.status == 2) {
                        this.getQrcode();
                        this.setState({
                            isSubmit: true,
                        })
                    } else if (res.data.status == 3) {

                    } else if (res.data.status == 4) {

                    }
                }
            })
    }
    getQrcode() {
        Ajax.GET(`/ldmshop/mini/data/testQrCode?authAppId=${this.props.adminReducer.shopData.mainAppId}`)
            .then(res => {
                if (res.code == 0) {
                    this.setState({
                        qrCodeUrl: res.data
                    })
                } else {
                    // message.error(res.errmsg)
                }
            })
    }
    getCategory() {
        Ajax.GET(`/ldmshop/category/getCheck?authAppId=${this.props.adminReducer.shopData.mainAppId}`)

            .then(res => {
                if (res.code == 0) {

                    // let arr = res.data.map(item=>{
                    //     let obj = {};
                    // // obj.value=item.id;
                    // obj.value=item.name
                    // obj.label=item.name;
                    // obj.children= item.children.map(item1=>{
                    //     let obj={};
                    //     obj.value=item1.name
                    //     obj.label=item1.name;
                    //     return obj;
                    // })
                    //     return obj;
                    // })
                    this.setState({
                        options: res.data
                    })
                } else {
                    // message.error(res.errmsg)
                }
            })
    }
    onChange(value) {
        console.log(value);
    }
    closeTip() {
        this.setState({
            isShow: false,
            isShowTips: false
        })
    }
    goTo() {
        window.location.href = "/admin/mainPage"
        sessionStorage.setItem("key", 2);
    }
    goSetPay(){
        window.location.href = "/admin/wechatPay"
        sessionStorage.setItem("key", 10);
    }
    submit() {
        // message.loading("上传审核中")
        let { options } = this.state;
        let obj =
        {
            authAppId: this.props.adminReducer.shopData.mainAppId,
            checkItemVOList: [
                {
                    address: "pages/index/index",
                    first_class: options.firstClass,
                    first_id: options.firstId,
                    second_class: options.secondClass,
                    second_id: options.secondId,
                    third_class: options.thirdClass,
                    third_id: options.thirdId,
                    title: "首页"
                }
            ]
        }
        let checkItemVOList = [];
        options.forEach((item, index) => {
            let options = {
                address: "pages/index/index",
                first_class: item.firstClass,
                first_id: item.firstId,
                second_class: item.secondClass,
                second_id: item.secondId,
                third_class: item.thirdClass,
                third_id: item.thirdId,
                title: "首页"
            }
            checkItemVOList.push(options);
        })
        obj.checkItemVOList = checkItemVOList;
        let back_token = sessionStorage.getItem("backToken");
        Ajax.POST(`/ldmshop/code/auditCode`, obj)
            // http.post(`/ldmshop/code/auditCode`, obj,{contentType: "application/json;charset-UTF-8",back_token:back_token})
            .then(res => {
                console.log(res)
                // message.hide();
                if (res.code == 0) {
                    // console.log(res);
                    message.success(res.data);
                    this.setState({
                        isSubmit: true
                    })
                    this.getQrcode();
                } else {
                    message.error(res.errmsg);
                }
            })
            .catch(res=>{
                message.error(res, 3);
            })
        // this.setState({
        //     isShow: true,
        // })
    }
    submitxcx() {
        let obj = {
            authAppId: this.state.mainAppId
        }


        Ajax.POST(`/ldmshop/code/upLoadCode`, obj)
            .then(res => {
                if (res && res.code == 0) {
                    this.setState({
                        visible:true
                    },()=>{
                        Ajax.GET(`/ldmshop/mini/data/testQrCode?authAppId=${this.state.mainAppId}`)
                        .then(res => {
                            if (res && res.code == 0) {
                                console.log('success')
                                this.setState({
                                    xcxCode: res.data,
                                    
                                })
                            }
                        })
                    })
                    

                }
            })
            .catch(err => {
                message.error('上传失败')
            })
    }
    confirm() {
        this.setState({
            isShow: false,
            isSubmit: true
        })
    }
    handleOk(){
        this.setState({
            visible:false
        })
    }
    handleCancel(){
        this.setState({
            visible:false
        })
    }



    revoke() {
        Ajax.GET(`/ldmshop/code/revoke?authAppId=${this.props.adminReducer.shopData.mainAppId}`)
   
            .then(res => {
                if (res.code == 0) {
                    // console.log(res);
                    message.success(res.data);
                    this.setState({
                        isSubmit: false
                    })
                } else {
                    // message.error(res.errmsg);
                }
            })
            .catch(errmsg => {
                message.error(errmsg)
            })
        // this.setState({
        //     isShow: true,
        // })
    }
    goAddGoods(){
        Ajax.GET(`/ldmshop/merchant/merChantToken?merChantId=${this.props.adminReducer.shopData.merchantId}`)
        // http.get(`/ldmshop/merchant/merChantToken?merChantId=${this.props.adminReducer.shopData.merchantId}`)
            .then(res => {
                if (res.code == 0) {
                    let data = res;
                    data.data.roleList[0] = "ROLE_SUPER_ADMIN";
                    data.data.name = data.data.phone;
                    let shopData = sessionStorage.getItem("shopData")
                    let shopName = JSON.parse(shopData).merchantName
                    let shopId = JSON.parse(shopData).merchantId
                    let logo = JSON.parse(shopData).logo
                    let menuId = JSON.parse(shopData).menuId
                    if(logo==null){
                        logo=''
                    }
                   
                    console.log(shopName)
                    // let url = `http://admin.shop.lingdaima.cn/user/login?data=${JSON.stringify(data)}&shopName=${shopName}&miniHeadImg=${logo}&shopId=${shopId}`
                    let env = process.env.NODE_ENV;
                    let url = "";
                    let userData = `data=${JSON.stringify(data)}&shopName=${shopName}&miniHeadImg=${logo}&shopId=${shopId}&menuId=${menuId}`
                    // console.log(a)
                    userData=secret.Encrypt(userData)
                    if (env === 'development') {
                        url = `http://localhost:8000/user/login?${userData}`
                      } else if (env === 'production') {
                        url = `http://admin.sifshop.cn/user/login?${userData}`
                      }
                    // let url = `http://admin.shop.lingdaima.cn/user/login?data=${JSON.stringify(data)}&shopName=${shopName}&miniHeadImg=${logo}&shopId=${shopId}`
                    // let a = decodeURI(url ? url.split('=')[2].split('&')[0] : '');
                    // let b = decodeURI(url ? url.split('=')[3].split('&')[0] : '');
                    // let c = decodeURI(url ? url.split('=')[4] : '');
                    // console.log(url)
                    // console.log(a)
                    // console.log(b )
                    // console.log(c)
                    window.location.href = url;
                } else {
                    message.error(res.errmsg);
                }
            })
    }
    render() {
        const { categoriesList } = this.props.adminReducer.shopInfo;
        // let arr = [categoriesList.first&&"",categoriesList.second&&""]
        return  <section>
        <Menu ></Menu>
    
        {this.state.isSubmit ? (
         
            <div className="SubmissionPages">
                <div className="tabs">
                    <span >小程序授权</span>
                    <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span>
                    小程序预览
                </span>
                    <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span className="active">小程序提交审核</span>
                    <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span >小程序完成发布</span>
                </div>
                <div className="successView">
                    <div className="flex">
                        <div className="icon">
                            <span className="iconfontAdmin iconchenggong"></span>
                        </div>
                        <div className="tipsView">
                            <div>你已提交审核，目前在审核中，审核大约需要1-2天，结果将通过微信告知。</div>
                        </div>
                    </div>
                    {/* <div className="tipsView1">
                        <div>查看体验版二维码</div>
                        <img src={this.state.qrCodeUrl}></img>
                    </div> */}
                    <div>

                    </div>

                    {/* <div className="typeView">
                        <div>服务类目：</div>
                        <div>
                            {
                                this.state.options.map((item, index) => {
                                    return <div key={index}>  {item.firstClass} > {item.secondClass} {!!item.thirdClass ? ">" : ''} {item.thirdClass} </div>
                                })
                            }
                        </div>
                    </div> */}
                    <div className="fooderButton" onClick={this.revoke.bind(this)}>撤销审核</div>
                </div>
            </div>
        ) : (<div className="SubmissionPages">
            <div className="title">微信小程序配置</div>
            <div className="tabs">
                <span >小程序授权</span>
                <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span>
                    小程序预览
                </span>
                <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                <span className="active">小程序提交审核</span>
                <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                <span >小程序完成发布</span>
            </div>
            <div className="content">
            {this.state.status && this.state.status.status == 0 ?
                    <div style={{fontSize:'16px',color:"#000"}}>
                        <span>您的小程序通过审核,请前往发布</span>
                    </div>
                    : ""
                }
                {this.state.status && this.state.status.status == 1 ?
                    <div>
                        <span>您的小程序未通过审核:</span>
                        <div className="unPassTips" dangerouslySetInnerHTML={{ __html: this.state.status.reason }}></div>
                    </div>
                    : ""
                }
                {this.state.status && this.state.status.status == 4 ?
                    <div><span>您的小程序未通过审核:</span>
                        <div className="unPassTips" dangerouslySetInnerHTML={{ __html: this.state.status.reason }}></div></div>
                    : ""
                }
                {this.state.status && this.state.status.status == 3 ?
                    <div className="unPassTips" >您的小程序审核已撤回</div> : ""
                }
                <div className="type">
                    <div>服务类目：</div>
                    <div>
                        {
                            this.state.options.map((item, index) => {
                                return <div key={index}>  {item.firstClass} > {item.secondClass} {!!item.thirdClass ? ">" : ''} {item.thirdClass} </div>
                            })
                        }
                    </div>
                    {/* <Cascader
                        defaultValue={['zhejiang', 'hangzhou']}
                        options={this.state.options}
                        // value={arr}
                        onChange={this.onChange}
                    /> */}
                </div>
                <div className="tips">如果找不到想要的服务类目，请先到<a href="https://mp.weixin.qq.com" target="_blank">微信公众平台</a>登录后，在“小程序信息”>“基本设置”中添加服务类目，再回到此页面刷新类目。</div>
                {/* <div className="input">
                    <div className="name">小程序标签：</div>
                    <div className="inputView">
                        <Input />
                    </div>
                </div>
                <div className="tips">输入多个关键字请用/隔开</div>
                <div className="tips">可以用小程序标签作为关键字，方便用户在微信中搜索到小程序</div> */}
            </div>
            <div className="double-button">
                <div className="fooderButton" onClick={this.submit.bind(this)}>{this.state.status && (this.state.status.status == 2 || this.state.status.status == 4 || this.state.status.status == 3) ? "重新审核" : "提交审核"}</div>
                {/* <div className="fooderButton" onClick={this.submitxcx.bind(this)}>更新小程序</div> */}
            </div>
            <div className="tips1" >建议<span onClick={this.goAddGoods.bind(this)}>[完善商品]</span>和<span onClick={this.goSetPay.bind(this)}>[设置微信支付]</span>再提交微信审核</div>

            <Tips
                title={"小程序提交审核"}
                isShow={this.state.isShow}
                closeTip={this.closeTip.bind(this)}
                width="576px"
                height="314px"
            >
                <div className="tipView">
                    <div className="image">
                        <span className="iconkafei iconfontAdmin"></span>
                    </div>
                    <div className="succssTip">审核提交成功</div>
                    <div className="about">
                        审核大约需要1-2天，结果将通过微信告知。若审核通过，约2小时后将发布生效。
                    </div>
                    <div className="confirm" onClick={this.confirm.bind(this)}>确认</div>
                </div>
            </Tips>
            <Tips
                title={"提示"}
                isShow={this.state.isShowTips}
                closeTip={this.closeTip.bind(this)}
            >
                <div className="tipView">
                    <div className="about1">
                        请先授权小程序
                    </div>
                    <div className="confirm" onClick={this.goTo.bind(this)}>确认</div>
                </div>
            </Tips>
            <Modal 
            title="体验二维码"
            visible={this.state.visible}
            onOk={this.handleOk.bind(this)}
            footer={null}
            onCancel={this.handleCancel.bind(this)}>
                <img src={this.state.xcxCode}></img>
            </Modal>
        </div>)}
        </section>
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    // actions: bindActionCreators(Store, dispatch)
})
export default withRouter(connect(mapStateToProps, null)(Submission));