import React, { Component } from 'react';
import './Tips.less'
class Tips extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount() {
    }
    componentWillMount() {
    }
    render() {
        let {isShow , title , width="404px",height="261px" ,closeTip} = this.props;
        return <div className={[isShow?"iSshow":"none","tipPage"].join(' ')}>
                <div className="mark" onClick={()=>closeTip()}></div>
                    <div className="context" style={{width: (!!width) ? width : "404px",height: (!!height) ? height : "261px"}}>
                        <div className="TipTitle">
                            <span >
                                {title||"标题"}
                            </span>
                            <span className="iconfontAdmin iconguanbi close" onClick={()=>closeTip()}></span>
                        </div>
                        {this.props.children}
                    </div>
                </div>
    }
}
export default Tips; 