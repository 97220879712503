import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../../components/Header/Header'
import Menu from '../../../components/Menu/Menu'
import { Switch, RouteWithSubRoutes } from 'react-router-dom'
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute'
import { withRouter } from 'react-router-dom'
import * as Admin from '../store/admin';
import Tips from '../../../components/Tips/Tips'
import http from '../../../http/http'
import { message, Input, Button, Icon } from 'antd'
import './testh5.less'
import { Ajax } from '../../../utils/ajax';

class testh5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xcxCode: '',
            mainAppId: 1,
            isShowTips: false,
            img:"",
            url:""
        }
    }
    componentDidMount() {
        if(this.props.history.location.state&&this.props.history.location.state.id){
            this.getShopData(this.props.history.location.state.id)
        }else{
        this.getShopData(this.props.adminReducer.shopData.merchantId)
        }

    }
    getShopData(id) {
        Ajax.GET(`/ldmshop/merchant/one/${id}`)
            .then(res => {
                if (res && res.code == 0) {
                    this.props.actions.setShopData(res.data);
                    this.setState({
                        url:res.data.webUrl,
                        img:res.data.urlImg
                    })

                } else {
                    message.error(res.errmsg)
                }
            })
    }
    closeTip() {
        this.setState({
            isShowTips: false
        })
    }
    goTo() {
        this.props.history.replace("/admin/mainPage")
        sessionStorage.setItem("key", 2);
    }
    componentWillMount() {

    }
    componentWillUnmount() {

    }
    go() {
        this.props.history.push({ pathname: "/admin/Submission" });
        sessionStorage.setItem("key", 12);
    }
    copy() {
        var inputElement = document.getElementById("copy_url");  //获取要赋值的input的元素
        inputElement.value = this.state.url;  //给input框赋值   
        inputElement.select();//选中input框的内容
        document.execCommand("Copy");// 执行浏览器复制命令
        message.success("复制网站成功", 2)
    }
    render() {

        return (
            <section>
                <Menu ></Menu>

                <div id="test" >
                    <div className="testPage">
                                <div className="test">
                                    <div className="testView">
                                    <div className="testViewItem">
                                    <p className="advice">您的h5网站</p>
                                    <img src = {this.state.img} className="images"></img>
                                    <div style={{marginTop:'20px',fontSize:"18px"}}>用手机扫码直接浏览</div>
                                    <div style={{marginTop:'20px'}}>
                                        地址：{this.state.url} <span  style={{marginLeft:'20px',display: "inline-block",padding: "2px 10px",border: "1px solid #888",borderRadius: "8px",cursor:"pointer"}} onClick={this.copy.bind(this)}>复制</span>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                <input id="copy_url" style={{ opacity: 0, position: "absolute" }}></input>
                   
                    </div>
                    <Tips
                        title={"提示"}
                        isShow={this.state.isShowTips}
                        closeTip={this.closeTip.bind(this)}
                    >
                        <div className="tipView">
                            <div className="about1">
                                请先授权小程序
                    </div>
                            <div className="confirm" onClick={this.goTo.bind(this)}>确认</div>
                        </div>
                    </Tips>

                </div>
            </section>


        );
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(testh5));