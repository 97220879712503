import Header from '../../common/Header'
import Footer from '../../common/Footer'
var React = require("react");
var createReactClass = require('create-react-class');

const $ = window.$;
require('./index.css')
// var configs = require("../../config")

export default createReactClass({
    getInitialState: function () {
        return {
            highlight: 1
        }
    },
    resizeClick() {
        if($(window).width()>750){
            // console.log(this.state.highlight);
            var self = this;
            $('.faq-body-left-list div').click(function () {
                self.setState({
                    highlight: $(this).index() + 1
                })
            })

            $('.faq-section-header').click(function () {
                $(this).next().slideToggle();
                $(this).parent().siblings().find('.faq-section-content').slideUp();
            })
            $('.faq-body-left-list div').click(function () {
                var index = $(this).index();
                $('.faq-section').eq(index).find('.faq-section-content').slideToggle();
                $('.faq-section').eq(index).siblings().find('.faq-section-content').slideUp();
            })
        }else {
           //移动端分页
            $(".faq-section-mobile-header-title").eq(0).css({
                borderColor: '#d5a256',
                color: '#333'
            });
            $(".faq-section-mobile-header-title").on('click', function(){
                var index = $(this).index()
                $(this).css({
                    borderColor: '#d5a256',
                    color: '#333'
                }).siblings().css({
                    borderColor: 'transparent',
                    color: '#999'
                });
                $('.faq-section-content').eq(0).hide();
                $('.faq-section-content').eq(1).hide();
                $('.faq-section-content').eq(2).hide();

                $('.faq-section-content').eq(index).show();
                // $(".faq-section-header-title").css("borderColor","transparent").parent().parent().children
                // $(this).css("borderColor","#d5a256").parent().parent().children('.faq-section-content').show();
            }) 
        }
    },
    componentDidMount() {
        this.resizeClick()
        $('.web').scrollTop(0);
    },

    switching(i) {
        this.setState({
            highlight: i
        })
    },
    openkefu() {
        var kefu101 = "http://wpa.qq.com/msgrd?v=3&uin=2435670291&site=oicqzone.com&menu=yes";
        var kefu102 = "mqqwpa://im/chat?chat_type=wpa&uin=2435670291&version=1&src_type=web&web_src=oicqzone.com";
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            window.open(kefu102);
        } else {
            window.open(kefu101);
        }
    },


    render: function () {
        return (
            <div id="faq">
                <Header type="faq" active="faq" cur="faq"></Header>
                <div className='faq-fullpage'>
                    <div className="container faq-wrapper">
                        <div className="faq-mobile-header">
                            <div className="faq-section-mobile-header-title">域名与备案</div>
                            <div className='faq-section-mobile-header-title'>网站编辑</div>
                            <div className='faq-section-mobile-header-title'>网站设计师</div>
                        </div>
                        <div className="faq row">
                            <div className="faq-title col-md-12">帮助中心</div>
                            <div className="faq-body col-md-12">
                                <div className="faq-body-left col-md-3 col-xs-12">
                                    <div className="faq-body-left-wrapper col-md-12">

                                        <div className='faq-left-title'>常见问题解答：</div>

                                        <div className="faq-body-left-list">

                                            <div
                                                className={this.state.highlight == 1 ? ' activeTab' : ''}>
                                                <a >一、域名与备案</a>
                                            </div>

                                            <div
                                                className={this.state.highlight == 2 ? ' activeTab' : ''}>
                                                <a>二、网站编辑</a>
                                            </div>

                                            <div
                                                className={this.state.highlight == 3 ? 'activeTab' : ''}>
                                                <a>三、网站设计师</a>
                                            </div>

                                        </div>

                                        <div className="faq-body-btn-wrapper">
                                            <div className="faq-body-btn" onClick={this.openkefu}>
                                                <img src='http://lingdaima.dotlinkface.com/faq/faq-kefu.png' alt="" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="faq-body-right col-md-9 col-xs-12">
                                    <div className="faq-row">
                                        <div className='faq-section' id='one'>
                                            <div className='faq-section-header'>
                                                <div className='faq-section-header-img'>
                                                    <img src='http://lingdaima.dotlinkface.com/faq/wangzhanjianshe.png' />
                                                </div>
                                                <div className='faq-section-header-title'>
                                                    <h4> 域名与备案</h4>
                                                    <h5>DOMAIN NAME AND RECORD</h5>
                                                </div>
                                            </div>
                                            <div className='faq-section-content'>
                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />
                                                        什么是SIFSHOP二级域名？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        SIFSHOP域名是免费为用户网站自动生成的二级域名，格式为：xxxx.lingdaima.cn，可修改。
                                                </div>
                                                </div>
                                                <div className="faq-row">
                                                    <div className="faq-row-header faq-row-header-top">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />如何更换二级域名？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        登录SIFSHOP，进入“用户中心>网站管理>网站域名”，可随时更换你的二级域名，修改后即时生效，二级域名为唯一。
                                                </div>
                                                </div>
                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />如何绑定自己的域名？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        <p>（1） 登录SIFSHOP，进入“用户中心>网站管理>网站域名”，可绑定你自己的域名。</p>
                                                        <p>（2） 在域名服务商提供的域名解析里，添加一条记录，记录类型选择CNAME，主机记录选择@或*，解析线路默认即可，记录值填site.lingdaima.cn，TTL默认10分钟即可，添加。</p>
                                                    </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />如何注册自己的的顶级域名？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        目前SIFSHOP暂无域名注册服务，建议到阿里云注册：<a target="_blank"
                                                            href='https://wanwang.aliyun.com'>https://wanwang.aliyun.com </a>
                                                    </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />域名如何解析？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        <div>在你的域名提供商处完成如下操作：</div>
                                                        <div className='subtitle'>1、登录购买域名的网站，进入域名解析页面。</div>
                                                        <div>
                                                            请登录你购买域名的网站，如阿里云万网、新网、美橙互联和GoDaddy等，选择你需要绑定的域名，进入该域名的解析设置界面。<br />
                                                            在解析设置页面，你会看到类似以下的界面，其中有三个需要你填写的参数。
                                                </div>
                                                        <img src='http://lingdaima.dotlinkface.com/faq/img-1.png' />
                                                        <div>
                                                            （1） 主机名/主机记录：主机名处填写你需要解析成为的二级域名前缀。比如你希望二级域名为 www.name.com，那主机名处就填 写“www”；如果你希望二级域名为 abc.name.com，那主机名处则填写“abc”。最常见的域名前缀 为“www”，也是我们推荐的前缀类型。
                                                    </div>
                                                        <div>
                                                            （2）记录类型：域名解析的类型有很多种，有A记录、CNAME别名记录、MX记录、NS记录等。而将域名解析到SIFSHOP的服务器，需要选择CNAME记录的方式来解析域名。
                                                    </div>
                                                        <div>
                                                            （3）记录值：记录值是你的网站需要解析到的IP地址或者服务器地址。由于你的域名需要绑定到SIFSHOP的网站，域名的解析地址就应该指向SIFSHOP的服务器地址。
                                                    </div>
                                                        <div className='subtitle'>2、解析域名</div>

                                                        <div>了解了我们需要设置的参数之后，我们现在一起来看看具体如何解析域名吧。</div>
                                                        <div>（1）首先需要把你的域名解析到SIFSHOP的哪一类服务器。SIFSHOP有两类服务器，分别是国内服务器和海外服务器。</div>
                                                        <div>（1.1）国内服务器，需备案，网站会在3～20个工作日内上线。如果网站的访客主要在国内，而你又不需要立刻上线网站，我们推荐解析到SIFSHOP国内服务器，此方式国内访问流畅且稳定。假设你的域名为： www.name.com，则国内服务器地址为：www.name.com.s.lingdaimadns.cn</div>
                                                        <div>（1.2）海外服务器，免备案，网站会在0～48小时内上线。国内和国外局部地区可能会出现访问不稳定的情况，但无需备案。如果你的访客主要在海外，或你希望立刻上线，之后再进行备案，我们推荐这种方式。假设你的域名为www.name.com，则海外服务器地址为www.name.com.g. lingdaimadns.cn。</div>
                                                        <div>（2）确定要解析到的服务器之后，添加一条主机记录为“www”的CNAME别名记录指向到SIFSHOP的服务器，例如海外服务器（www.name.com.g. lingdaimadns.cn），TTL值为默认 ，使 www.name.com 能够访问你的网站，如下图：</div>
                                                        <img src='http://lingdaima.dotlinkface.com/faq/img-2.png' />
                                                        <div>（3）如果你希望不仅能够使用 www.name.com 来访问你的网站，也能直接使用 name.com 来访问网站，推荐使用URL跳转或301重定向的功能，让 name.com 跳转到 www.name.com 来打开网站。 </div>
                                                        <img src='http://lingdaima.dotlinkface.com/faq/img-3.png' />
                                                        <div>
                                                            若域名提供商不支持URL 跳转或301重定向的功能，你可以添加一条主机记录为“@”的CNAME别名记录解析指向到SIFSHOP的服务器，TTL值默认，这样可以直接使用 name.com 访问你的网站。
                                                    </div>
                                                        <img src='http://lingdaima.dotlinkface.com/faq/img-4.png' />
                                                        <div>
                                                            注意：<br />如果该域名下已经有www的CNAME别名记录或者 A 记录，请确保将这些设置删除或进行重新编辑，使该域名下只存在一组解析。<br />
                                                            不要设置跳转到 lingdaima.cn 的二级域名（例如 name.lingdaima.cn ）。请不要进行页面代码跳转至SIFSHOP网址，以免影响网站稳定性及 SEO。
                                                    </div>
                                                        <div className='subtitle'>3. 保存所有已完成的操作</div>
                                                        <div>
                                                            如果选择解析到海外服务器，网站成功解析上线需要0~48小时，最终生效取决于各运营商的缓存刷新时间，请耐心等待！
                                                    </div>

                                                    </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />域名备案流程？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        <div>   根据国家工信部要求，解析到任何国内服务器的域名需要先完成ICP备案。<br />注意：</div>
                                                        <div>1、SIFSHOP为付费用户免费提供备案所需的备案服务号，一个备案服务号对应一个付费网站。</div>
                                                        <div>2、有的域名后缀目前不支持进行备案，请根据文档【该域名后缀不支持备案】一文查看你的域名是否可以备案。</div>
                                                        <div className='subtitle'>第一步：申请备案服务号</div>
                                                        <div>请按照如下格式发送邮件至：lingdaima@lingdaima.cn</div>
                                                        <div>邮件主题："[网站名] 备案申请"</div>
                                                        <div>邮件内容： </div>
                                                        <div>1、SIFSHOP账号邮箱 </div>
                                                        <div>2、网站的SIFSHOP二级域名（例如: abc.lingdaima.cn） </div>
                                                        <div>3、自定义域名（例如: abc.com） </div>
                                                        <div>4、主体名称（单位全称或个人姓名） </div>
                                                        <div>5、主体所在地域（省，市） </div>
                                                        <div>6、网站主要内容描述</div>
                                                        <div className='subtitle'>第二步：设置域名解析</div>
                                                        <div>假设你的域名为www.name.com，在域名解析中修改/添加 CNAME 记录，指向SIFSHOP国内服务器 "www.name.com.dns.lingdaima.cn " <br />
                                                            想了解如何修改/添加域名 CNAME 记录，请参考这里的第二部分</div>

                                                        <div>注意：设置域名解析到SIFSHOP国内的服务器直到备案成功之前，阿里云会暂时屏蔽网站的内容，这属于正常情况，请不要担心，备案成功后就会自动解除屏蔽并可以直接正常使用了，你可能会看到：</div>

                                                        <img src='http://lingdaima.dotlinkface.com/faq/img-5.png' />
                                                        <div className='subtitle'>第三步：前往阿里云系统备案</div>
                                                        <div>收到SIFSHOP客服确认邮件与备案服务号后，请前往阿里云备案系统注册一个备案账号，如果已在阿里云完成过备案并拥有备案账号，请直接登录</div>

                                                        <div>根据具体情况，进行备案操作，强烈建议查看以下教程，请根据域名的情况，选择以下的备案流程：</div>
                                                        <div>－ 首次在阿里云进行备案</div>
                                                        <div>－ 备案主体原备案在阿里云，在备案主体下新增网站</div>
                                                        <div>－ 备案主体原备案不在阿里云，在备案主体下新增网站</div>

                                                        <div>（1）登录备案系统，开始备案。输入必选项与希望备案的域名地址</div>
                                                        <div>（2）跟着提示，继续完成填写备案的域名及主体证件信息
                                                注意：请根据网站内容情况，谨慎选择”个人“或”企业“备案类型，如网站内容与备案类型不相符，根据部分地方管局规定，可能不予以通过。相关政策法规及审核流程，可拨打阿里云备案咨询电话：95187转3</div>
                                                        <div>（3）当问到“产品类型”时，请选择“阿里云”</div>
                                                        <div>（4）输入SIFSHOP客服提供的备案服务号，点击“验证”继续</div>
                                                        <div>（5）继续跟着提示，填写主体信息。如有多个网站提交申请，在填写完网站信息可点击“保存，并继续添加网站”</div>
                                                        <div>（6）如无其他网站备案，直接上传备案资料。</div>
                                                        <div>（7）下载核验单，签字并发送图片。</div>
                                                        <div>（8）完成提交后，提交备案至初审。</div>
                                                        <div>（9） 初审通过后，登录备案系统申请幕布，收到幕布后自行拍照并上传照片审核。把所需的材料邮寄到阿里云。</div>
                                                        <div>（10）完成阿里云的审核后，请耐心等待管局审核。
                                                在阿里云提交完毕所有资料后，请保持手机畅通以待，等待管局审核并通知备案结果（一般在3~20个工作日内）。备案进度可登陆阿里云备案系统实时查看，结果将以短信及邮件形式通知。</div>
                                                        <div className='subtitle'>第四步：备案号公布</div>
                                                        <div>ICP备案成功后，将收到工信部发放的备案号，请及时把备案号添加在网站页脚公示，成功后可以在工信部官网查询你的备案信息</div>
                                                        <div>注意：备案成功后切勿更改之前操作备案时设置的 CNAME 记录，阿里云将会实时检测网站的备案信息及是否正确解析，如有错漏或不符，将会停止网站的解析请求，访客将无法访问网站。</div>
                                                    </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />原域名已备案怎么办？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        <div className='subtitle'> 一、域名是在阿里云备案中心备案的 </div>
                                                        <div>如果你的域名已经在阿里云备案中心完成过备案了，则不需要再次备案，网站可直接访问。 </div>

                                                        <div className='subtitle'>二、域名是在其他接入商备案的，而不是在阿里云备案中心。 </div>
                                                        <div>由于解析到SIFSHOP国内服务器的域名，需要在阿里云备案中心完成备案，网站才能上线，所以如果你的域名不是在阿里云备案中心备案的，则需要将你的域名接入阿里云备案中心。【查看阿里云备案步骤】 </div>
                                                    </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />多域名访问同个网站，怎么设置？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        请移步: <a target="_blank"
                                                            href='https://bbs.aliyun.com/read/263322.html?spm=5176.10695662.1996646101.searchclickresult.3fbcb9a0zBp0KD'>https://bbs.aliyun.com/read/263322.html?spm=5176.10695662.1996646101.searchclickresult.3fbcb9a0zBp0KD</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='faq-section' id='two'>
                                            <div className='faq-section-header'>
                                                <div className='faq-section-header-img'>
                                                    <img src='http://lingdaima.dotlinkface.com/faq/bianji.png' />
                                                </div>
                                                <div className='faq-section-header-title'>
                                                    <h4> 网站编辑</h4>
                                                    <h5>WEBSITE  EDITIMG</h5>
                                                </div>
                                            </div>
                                            <div className='faq-section-content'>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />快速创建网站？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        <div>以下几种方式可创建网站：</div>
                                                        <div>1. 登录SIFSHOP，进入“设计师作品>点击预览>选中>编辑>发布”</div>
                                                        <div>2. 登录SIFSHOP，进入“用户中心>网站管理>创建新网站>设计师作品>点击预览>选中>编辑>发布“</div>
                                                    </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />SEO设置？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        请移步: <a target="_blank"
                                                            href='https://yq.aliyun.com/articles/266885?spm=5176.10695662.1996646101.searchclickresult.5f395e94ZCBpq5'>https://yq.aliyun.com/articles/266885?spm=5176.10695662.1996646101.searchclickresult.5f395e94ZCBpq5</a>
                                                    </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />添加第3方统计？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        登录SIFSHOP，进入“用户中心>网站管理>设置网站>流量统计”，输入第3方的统计代码即可。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />网站暂时关闭访问？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        如果你的网站想暂时关闭，可登录SIFSHOP，进入“用户中心>网站管理>设置网站>网站关闭”，输入关闭提示文案，用户访问你的网站时会显示此文案，可随时开启网站。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />去掉网站底部“SIFSHOP”版权信息？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        需要升级网站套餐才可去掉底部版权信息，去升级套餐>>
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />添加模板？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        在编辑网站时，点击左侧添加组件，进入添加模板，选择要添加的模板，点击即可添加。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />添加页面？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        在编辑网站时，左侧页面管理里，可随时添加页面。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />为模板添加动画？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        在编辑网站时，可双击编辑，在左侧编辑区，可选择模块的动画效果。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />模块对齐？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        在编辑网站时，当选中多个模块时，模块顶部会出现对齐图标，点击即可完成对齐。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />模块复制？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        在编辑网站时，当选中某个模块时，点击模块顶部的复制图标，即可复制相同的模块。
                                                </div>
                                                </div>
                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />上传图片？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        在编辑网站时，点击左侧添加组件，选择图片，直接上传图片即可。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />如何添加组件？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        在编辑网站时，点击左侧添加组件，选择要添加相应的组件类型，点击即可添加到右侧页面中。
                                                </div>
                                                </div>


                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />如何编辑文字？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        在编辑网站时，可直接双击网站上的文字进行编辑。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />如何预览H5效果？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        登录后，在编辑网站时，可在页面顶部直接切换网站在PC\PAD\H5各端的真实效果。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />保存编辑内容？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        登录后，对网站进行编辑，编辑后点击保存即可。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />如何发布网站？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        登录后，对网站进行编辑，编辑后点击发布即可。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />需要单独做一套H5页面吗？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        不需要，所有设计的网站自动兼容H5页面
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' /> 网站如何分享到朋友圈？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        登录SIFSHOP，进入“用户中心>网站管理>设置网站>分享设置”，生成你网站的二维码，下载后就可以分享给你的微信好友或朋友圈了。
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='faq-section' id='three'>
                                            <div className='faq-section-header'>
                                                <div className='faq-section-header-img'>
                                                    <img src='http://lingdaima.dotlinkface.com/faq/shejidashi.png' />
                                                </div>
                                                <div className='faq-section-header-title'>
                                                    <h4> 网站设计师</h4>
                                                    <h5>WEBSITE DESIGNER</h5>
                                                </div>
                                            </div>
                                            <div className='faq-section-content'>
                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />   如何成为网站设计师？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        注册时选择设计师选项，输入注册信息即可注册成为设计师。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' /> 如何提交设计师作品？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        登录SIFSHOP，进入“个人中心>作品管理>创新作品>选择结构>编辑>发布”，你的作品即可发布，发布的作品需要官方审核。
                                                </div>
                                                </div>

                                                <div className="faq-row">
                                                    <div className="faq-row-header">
                                                        <img src='http://lingdaima.dotlinkface.com/faq/question.png' />  如何修改作品？
                                                </div>
                                                    <div className="faq-row-anwser">
                                                        登录SIFSHOP，进入“个人中心>作品管理>编辑作品>发布”，你编辑的作品需要官方审核。
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer cur="faq"></Footer>
            </div>
        )
    }
})
