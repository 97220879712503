import React from 'react';
import './casechannel.css';
import {Link} from 'react-router-dom';
const $ = window.$;
export default class CaseChannel extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      caseList: [
        {
          imgsrc: 'http://lingdaima.dotlinkface.com/casechannel/puxin.lingdaima.jpg',
          name: '璞心农场',
          urllink: 'http://puxin.lingdaima.cn/',
          address: '湖州',
          txt: '做网站像种田，自然农品的标准是没有标准，就是最好的标准。心法是门内无门，门外有门；唯借假修真而已。在纷繁的社会形态中，守好本分二字不只是小难。SIFSHOP关心客户需求，注重消费者体验，高效沟通，溯源本质，快速产出。优秀的团队，力荐。',
          createrimg: '2',
          createrimg1: '3',
          position: '创始人',
        },
        {
          imgsrc: 'http://lingdaima.dotlinkface.com/casechannel/njth.lingdaima.jpg',
          name: '农家土货',
          urllink: 'http://njth.lingdaima.cn/',
          address: '上海',
          txt: 'SIFSHOP公司是一家非常专业的网站一体化解决方案服务商，能提供全方位的网站设计、运营、维护等一系列专业服务，满足各行业对网站的综合需求，所设计的web站点兼顾美感、灵动、科学、体验等特点，是一家非常理想的合作伙伴。其团队工作人员热情、专业、服务意识强，能准确理解客户的需求，提供完美的设计方案和交付成果。',
          createrimg: '5',
          createrimg1: '6',
          position: '创始人',
          marginTop: true,
        },
        {
          imgsrc: 'http://lingdaima.dotlinkface.com/casechannel/shanqiu.lingdaima.jpg',
          name: '山丘工业设计',
          urllink: 'http://shanqiu.lingdaima.cn',
          address: '杭州',
          txt: '专业的人，做专业的事，靠谱！ 网页的设计风格主流，大气和国际范，网页整体配色和我提供的案例背景色同色系，视觉感受柔和，比较意外的是网页还可以自动匹配不同尺寸的屏幕，手机，相当的强大。很多客户看过我的新网站，都会向我打听是谁家做的，好评比较一致。SIFSHOP真是个不错的选择。',
          createrimg: '8',
          createrimg1: '9',
          position: '创始人兼设计总监',
        },
        {
          imgsrc: 'http://lingdaima.dotlinkface.com/casechannel/www.duoleduo.jpg',
          name: '百纳汇商贸网',
          urllink: 'http://www.duoleduo.video/',
          address: '杭州',
          txt: '我是浙江文辰贸易有限公司的，初次相识SIFSHOP科技，一个阳光的团队。能按照客户的要求进行制作，更多的为客户考虑。我公司定制的“百纳汇商贸网”宣传网页，在百度上上线，推广宣传的效果不错！有了这次的满意合作体验，希望以后有更多的合作机会!祝愿SIFSHOP科技越走越远，合作的平台越来越多，更加优秀',
          createrimg: '11',
          createrimg1: '12',
          position: 'CEO',
          marginTop: true,
        },
      ],
    };
  }

  componentDidMount () {
    $('.web').scrollTop(0);
  }

  renderItem () {
    var List = this.state.caseList.map ((item, index) => (
        <div style={{position: 'relative'}}>
          <div className="case-item col-md-12 col-xs-12" key={index}>                     
            <div className="item-left  col-md-4 col-xs-12">
              <img src={item.imgsrc} />
              <div className="case-view">
                <a href={item.urllink} target="_blank"></a>
              </div>
            </div>
            <div className="item-right col-md-11   col-xs-12">
              <div className="row">
                <div className="item-right-1 col-md-12">
                  <div className="row">
                    <div className="case-name pull-left">
                      <span>{item.name}</span>                  
                      <span className="case-name-border"></span>
                      <span className="case-address-phone">
                        <span className="icon iconfont icon-dizhi"></span>
                        <span> {item.address} </span>
                      </span> 
                    </div>
                  </div>
                </div>
                <div className="item-right-2 clearfix">
                  <div className='item-right-txt'>{item.txt}</div>
                </div>
                <div className={ item.marginTop ? 'item-right-3 margintop12' : 'item-right-3' } > 
                  <img src={ 'http://lingdaima.dotlinkface.com/casechannel/casechannel-' + item.createrimg + '.png' } />
                  <span className="case-position">{item.position}</span>
                  <img src={ 'http://lingdaima.dotlinkface.com/casechannel/casechannel-' + item.createrimg1 + '.png' } />                
                </div>
                <div className="case-urllink pull-left">
                  <span className="icon iconfont icon-weibiaoti40" />
                  <span className="icon iconfont icon-lianjie" />
                  <a href={item.urllink} target='_blank'><span> {item.urllink}</span></a>
                </div>
              </div>
            </div>
            <div className="case-address">
              <span className="icon iconfont icon-dizhi" />
              <span> {item.address} </span>
            </div>                 
          </div>
          <div className="dashed-line"><div></div></div>
        </div>
    ));

    return List;
  }

  render () {
    return (
      <div className="casechannel">
        <div className="container-fluid">
          <div className="row">
            <div className="container case-channel">
              <div className="row">
                <h1 className="txtcenter">客户案例</h1>
                <div  className="clearfix"></div>
                <div className="txt-en">
                  <span className="txt-left"><i></i></span>
                  <span className="txt-center">CUSTOMER  CASE</span>
                  <span className="txt-right"><i></i></span>
                </div>
                <div className="case-list">
                  {this.renderItem ()}
                </div>
                <Link ref="onetoone" className="ontoone-btn" to="/onetoone">
                  <div className="goto-onetoone">                      
                    <span className="ontoone-btn-txt">
                      设计师1对1建站服务
                      <span className="left-top border-span"></span>  
                      <span className="right-top border-span"></span>  
                      <span className="left-bottom border-span"></span>  
                      <span className="right-bottom border-span"></span>
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
