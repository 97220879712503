/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-25 16:06:01
 * @LastEditTime: 2019-09-18 15:04:08
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react';
import { Upload, Modal, Icon, message,Button } from 'antd';

import './index.css'
class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: [],
      isEditor: false,
    };
  }
  componentDidMount() {
    if (this.state.isEditor) {
      console.log('isEditor');
      return;
    }

    if (this.props.data.length && this.props.isEditor) {
      let arr = [];
      this.props.data.forEach((item, index) => {
        let obj = {
          uid: index,
          status: 'done',
          url: item,
        };
        arr.push(obj);
      });

      this.setState(
        {
          fileList: arr,
          isEditor: this.props.isEditor,
        },
        () => console.log(this.state)
      );
    }
  }
  componentWillReceiveProps(nextprops) {
    console.log(nextprops);
    if (this.state.isEditor) {
      console.log('isEditor');
      return;
    }

    if (nextprops.data.length && nextprops.isEditor) {
      let arr = [];
      nextprops.data.forEach((item, index) => {
        let obj = {
          uid: index,
          status: 'done',
          url: item,
        };
        arr.push(obj);
      });

      this.setState(
        {
          fileList: arr,
          isEditor: nextprops.isEditor,
        },
        () => console.log(this.state)
      );
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });
  beforeUpload = file => {
    console.log(file);
    return new Promise((resolve, reject) => {
      let fileName = file.name,
        index = fileName.lastIndexOf('.'),
        str = fileName.substr(index);
      if (str !== '.png' && str !== '.jpg' && str !== '.jpeg' && str !== '.bmp' && str !== '.gif') {
        message.error('只能上传图片！');
        this.setState({
          fileList: [],
        });
        reject();
      }
      if (this.state.fileList.length >= this.props.maxImg) {
        message.error(`最多上传${this.props.maxImg}张图片！`);
      }
      resolve();
    });
  };
  customRequest = detail => {
    let file = detail.file;
    fetch('/ldmshop/oss/getSign')
      .then(res => {
        return res.json();
      })
      .then(res => {
        const serverURL = res.data.host;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        const key = `${new Date().getTime()}_${file.name}`;
        fd.append('OSSAccessKeyId', res.data.accessid);
        fd.append('policy', res.data.policy);
        fd.append('Signature', res.data.signature);
        fd.append('key', key);
        fd.append('success_action_status', '200');
        const successFn = response => {
          detail.onSuccess(serverURL + '/'+ key);
          this.props.uploadSuccess(this.state.fileList);
          // this.props.Uploading(false)
        };
        const progressFn = event => {
          // 上传进度发生变化时调用param.progress
          let percent = (event.loaded / event.total) * 100;
          detail.onProgress({
            percent,
          });
          // this.props.Uploading(true)
        };
        const errorFn = response => {
          // 上传发生错误时调用param.error
          detail.onError();
        };
        xhr.upload.addEventListener('progress', progressFn, false);
        xhr.addEventListener('load', successFn, false);
        xhr.addEventListener('error', errorFn, false);
        xhr.addEventListener('abort', errorFn, false);
        fd.append('file', file);
        xhr.open('POST', serverURL, true);
        xhr.send(fd);
      });
    return false;
  };
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  handleChange = data => {
    if (data.fileList.length >= this.props.maxImg)
      data.fileList = data.fileList.splice(0, this.props.maxImg);
    this.setState({ fileList: data.fileList });
    this.props.uploadSuccess(data.fileList);
  };
  onRemove = file => {
    // let list = this.state.fileList
    // console.log(this.state.fileList)
    // this.props.uploadSuccess(list)
  };
  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        {/* <Icon type="plus" />
        <div className="ant-upload-text">Upload</div> */}
        上传图片
      </div>
    );
    return (
      <div id="clearfix">
        <Upload
          // listType="text"
          fileList={fileList}
          onPreview={this.handlePreview}
          customRequest={this.customRequest}
          onChange={this.handleChange}
          onRemove={this.onRemove}
          beforeUpload={this.beforeUpload}

          accept="image/*"
        >
          {/* {uploadButton} */}
          <p style={{color:'#D5A256',fontWeight:'500'}}>上传图片</p>
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}
export default UploadImages;
