import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import icon from '../../../assets/images/xcx.jpg'
import Menu from '../../../components/Menu/Menu'
import './Auth.less'
import Tips from '../../../components/Tips/Tips'
import http from '../../../http/http'
import { Ajax } from '../../../utils/ajax'
import { withRouter } from 'react-router-dom'
import * as Admin from '../store/admin';
import { message } from 'antd'
import { getAuthCode } from '../common/utils'
class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            isShowImage: false,
            image: '',
            title:""
        }
    }
    componentDidMount() {
        this.getData()
    }
    componentWillMount() {
    }
    goToInformation() {
        this.props.history.push("/admin/Information")
    }
    closeTip() {
        this.setState({
            isShow: false,
            isShowImage: false
        })
    }
    getAuth(title,type) {
        let merchantId=this.props.adminReducer.shopData.merchantId;
        if(type==1){
            getAuthCode(merchantId,"",title)
        }else{
            getAuthCode(merchantId,"",title,"/admin/test",11)
        }
        // let xhr = new XMLHttpRequest();
        // xhr.open('GET', `/ldmshop/auth/officialScanGuideAuth?merchantId=${this.props.adminReducer.shopData.merchantId}`, true);
        // let backToken = sessionStorage.getItem("backToken") == null ? '' : sessionStorage.getItem("backToken")
        // xhr.setRequestHeader('back_token', backToken);
        // xhr.responseType = 'arraybuffer'; // 重点
        // let that = this; // 这个不是必须，只是用来保存this的指向
        // xhr.onload = function (e) {
        //     if (this.status == 200) {
        //         let result = this.response;
        //         console.log(result)
        //         let imgUrl = `data:image/png;base64,${window.btoa(
        //             new Uint8Array(result).reduce(
        //                 (data, byte) => data + String.fromCharCode(byte),
        //                 ""
        //             )
        //         )}`;
        //         that.setState({
        //             isShowImage: true,
        //             image: imgUrl
        //         })
        //     }
        // };
        // xhr.send();

    }
    getData() {

        Ajax.GET(`/ldmshop/mini/data/getWeChatMiniInfo?authAppId=${this.props.adminReducer.shopData.mainAppId}`)
            .then(res => {
                console.log(res);
                if (res.code == 0) {
                    this.props.actions.setShopInfo(res.data)
                    this.getWeixinImage(res.data.qrCodeUrl)
                } else {
                    message.error(res.errmsg)
                }
            })


    }
    getWeixinImage(url) {
        window.WxImgTemp = '';
        let time = new Date().getTime(),
            frameid = 'wxImg_' + time;
        console.log(url)
        window.WxImgTemp = '<img id="img" style="width:100%" src=\'' + url + '?' + time + '\' />\
        <script>window.onload = function() {\
        parent.document.getElementById(\'' + frameid + '\').height = document.getElementById(\'img\').height+\'px\'; \
        }</script>';
        this.refs.iframe.src = "javascript:parent.WxImgTemp";
    }


    render() {
        if (!this.props.adminReducer.shopData.mainAppId) {
            this.props.history.replace("/admin/mainPage")
        }
        const { miniNickName, miniHeadImg, signature, verifyTypeInfoStr } = this.props.adminReducer.shopInfo;
        return <section>
            <Menu ></Menu>

            <div className="pages1">
                <div className="title">
                微信小程序配置
            </div>
                <div className="tabs">
                    <span className="active">
                        小程序授权
                </span>
                <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span>
                    小程序预览
                    </span>
                    <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span>
                        小程序提交审核
                </span>
                    <span className="iconfontAdmin iconfanhuizuojiantouxiangzuoshangyibuxianxing icon">  </span>
                    <span>
                        小程序完成发布
                </span>
                </div>
                <div className="content">
                    <div className="tips">恭喜你！小程序授权完成</div>
                    <div className="list">
                        <span>小程序名称：</span>
                        <span>{miniNickName}</span>
                    </div>
                    <div className="list">
                        <span>小程序图标：</span>
                        <img className="logo" src={miniHeadImg}></img>
                    </div>
                    <div className="list">
                        <span>小程序介绍：</span>
                        <span>{signature}</span>
                    </div>
                    <div className="list">
                        <span>小程序认证：</span>
                        <span>{verifyTypeInfoStr}</span>
                    </div>
                    <div className="list">
                        <span>小程序码：</span>
                        {/* <img className="share" src={qrCodeUrl}></img> */}
                        <iframe ref="iframe" className="share" >

                        </iframe>
                    </div>
                    <div className="buttonList">
                        <div className="button" onClick={() => {this.getAuth("更新小程序信息",1)}}>
                            更新信息<span className="iconfontAdmin iconshuangjiantou-copy"></span>
                        </div>
                        <div className="button" style={{marginLeft:"20px"}} onClick={() => {this.getAuth("重新授权小程序",2) }}>
                            重新授权<span className="iconfontAdmin iconshuangjiantou-copy"></span>
                        </div>
                        {/* <div className="button1" onClick={() => this.goToInformation() }>
                        获取小程序信息<span className="iconfontAdmin iconshuangjiantou-copy"></span>
                    </div> */}
                        <a style={{ marginLeft: '30px', marginTop: '10px', color: 'red' }} target="_blank" href="https://mp.weixin.qq.com/">如何取消授权?</a>
                    </div>
                </div>
                <Tips
                    title={this.state.isShowImage ? this.state.title : ""}
                    isShow={this.state.isShow || this.state.isShowImage}
                    closeTip={this.closeTip.bind(this)}
                    width={this.state.isShowImage ? '400px' : ''}
                    height={this.state.isShowImage ? '430px' : ''}
                >
                    {this.state.isShow ? <div className="tipView">
                        <div className="list">
                            <span>小程序认证：</span>
                            <span>{verifyTypeInfoStr}</span>
                        </div>
                        <div className="TipsText">
                            取消授权功能将失效请确认取消？
                    </div>
                        <div className="TipsButton">
                            <div className="TipsButton1">确认</div>
                            <div className="TipsButton1" onClick={this.closeTip.bind(this)}>取消</div>
                        </div>
                    </div> : null}
                    {this.state.isShowImage ? <div className="tipView1">
                        <img src={this.state.image}></img>
                    </div> : null}
                </Tips>
            </div>
        </section>

    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));