

import { message } from 'antd'
function getBackToken(){
  return sessionStorage.getItem("backToken") == null ? '' : sessionStorage.getItem("backToken");
}
export const Ajax = {
  /**
   * 封装fetch的get请求方法
   */
  GET: (url) => {
    return new Promise((reslove, reject) => {
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'mark': 'back',
          'back_token': getBackToken()
        }
      })
        .then(res => {
          if (res.status >= 500) {
            message.error("服务器出错啦", 3)
            reject("服务器出错啦")
            return false
          }
          if (res.status >= 400 && res.status < 500) {
            message.error("请求参数错误", 3)
            reject("请求参数错误")
            return false
          }
          return res.json()
        })
        .then(res => {
          if (!res) {
            return false
          }
          if (res.code !== 0) {
            if (res.code == 50066) {
              // localStorage.removeItem("name")
              // localStorage.removeItem("phoneNumber")
              // localStorage.removeItem("headImg")
              // localStorage.removeItem("xcxData")
              // localStorage.removeItem('backToken')
              message.error(res.errmsg, 3)
              // window.location.replace("/login"); 
              return res.data
            }

            // message.error(res.errmsg, 3)
            reject(res.errmsg)
            return false
          }
          reslove(res)
        })
        .catch(err => {
          // message.error(err, 3)
          reject(err)
        })
    })
  },
  DELETE: (url) => {
    return new Promise((reslove, reject) => {
      fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'mark': 'back',
          'back_token': getBackToken()
        }
      })
        .then(res => {
          if (res.status >= 500) {
            message.error("服务器出错啦", 3)
            reject("服务器出错啦")
            return false
          }
          if (res.status >= 400 && res.status < 500) {
            message.error("请求参数错误", 3)
            reject("请求参数错误")
            return false
          }
          return res.json()
        })
        .then(res => {
          if (res.status == true) {
            reslove(res)

          }
          if (!res) {
            return false
          }
          if (res.code !== 0) {
            if (res.code == 3000) {
              localStorage.removeItem("username")
              localStorage.removeItem("username")
              localStorage.removeItem("islogin")
              localStorage.removeItem('Token')

              window.location.replace("/login");
              return res.data
            }
            message.error(res.errmsg, 3)
            reject(res.errmsg)
            return false
          }
          reslove(res)
        })
        .catch(err => {
          message.error(err, 3)
          reject(err)
        })
    })
  },
  /**
   * 封装fetch的post请求方法
   */
  POST: (url, data) => {
    return new Promise((reslove, reject) => {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          //   'Content-Type': 'application/json'
          'Content-Type': 'application/json',
          'mark': 'back',
          'back_token': getBackToken()
        }
      })
        .then(res => {
          if (res.status >= 500) {
            message.error("服务器出错啦", 3)
            reject("服务器出错啦")
            return false
          }
          if (res.status >= 400 && res.status < 500) {
            message.error("请求参数错误", 3)
            reject("请求参数错误")
            return false
          }

          return res.json()
        })
        .then(res => {
          if (res.code !== 0) {
            if (res.code == 3000) {
              localStorage.removeItem("username")
              localStorage.removeItem("username")
              localStorage.removeItem("islogin")
              localStorage.removeItem('Token')

              window.location.replace("/login");
              return res.data
            }
            // message.error(res.errmsg, 3)
            reject(res.errmsg)
            return false
          }
          reslove(res)
        })
        .catch(err => {
          message.error(err, 3)
          reject(err)
        })
    })
  },
  /**
   * 封装fetch的put请求方法
   */
  PUT: (url, data) => {
    return new Promise((reslove, reject) => {
      fetch(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.status >= 500) {
            message.error("服务器出错啦", 3)
            reject("服务器出错啦")
            return false
          }
          if (res.status >= 400 && res.status < 500) {
            message.error("请求参数错误", 3)
            reject("请求参数错误")
            return false
          }
          return res.json()
        })
        .then(res => {
          if (!res) {
            return false
          }
          if (res.errno !== 0) {
            message.error(res.errmsg, 3)
            reject(res.errmsg)
            return false
          }
          reslove(res)
        })
        .catch(err => {
          message.error(err, 3)
          reject(err)
        })
    })
  }
}

