import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown, Button, Icon, message } from 'antd';
import { connect } from 'react-redux';
import './Header.less'
import logo from '../../assets/images/logo.png'
import http from '../../http/http'
import {Ajax} from '../../utils/ajax'
import { Link } from 'react-router-dom';

const menu = (
    <Menu >
      <Menu.Item key="1" onClick={handleMenuClick}>
        <Icon type="setting" />
        账户设置
      </Menu.Item>
      <Menu.Item key="2" onClick={handleMenuClick2}>
        <Icon type="user" />
        切换用户
      </Menu.Item>
      <Menu.Item key="3" onClick={handleMenuClick3}>
        <Icon type="close-circle" />
        退出
      </Menu.Item>
    </Menu>
  );
  function handleMenuClick(e) {
    let url = `http://shop.lingdaima.cn/admin/`
    window.location.href = url;
  }
  function handleMenuClick2(e) {
    let url = `http://shop.lingdaima.cn/admin/`
    window.location.href = url;
  }
  function handleMenuClick3(e) {
    let url = `http://shop.lingdaima.cn/admin/`
    window.location.href = url;
  }


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        let phoneNumber = sessionStorage.getItem("phoneNumber")!=='null' ? sessionStorage.getItem("phoneNumber") : ''
        let name = sessionStorage.getItem("name")!=='null' ? sessionStorage.getItem("name") : ''
        let headImg = sessionStorage.getItem("headImg")!= 'null' ? sessionStorage.getItem("headImg") : ''

        // var userName = sessionStorage.getItem("phoneNumber")
        // var name = sessionStorage.getItem("name") !=='null' ? sessionStorage.getItem("name") : ''
        // let headImg = sessionStorage.getItem("headImg") != 'null' ? sessionStorage.getItem("headImg") : ''
        this.setState({
            phoneNumber,name,headImg
        })
    }
    componentWillMount() {
    }
    quit() {
        Ajax.GET(`/ldmshop/admin/logout`)
        // http.get(`/ldmshop/admin/logout`)
            .then(res => {
                if (res && res.code === 0) {
                    sessionStorage.removeItem("backToken");
                    sessionStorage.removeItem("key");
                    sessionStorage.removeItem("shopData")
                    sessionStorage.removeItem("phoneNumber")
                    sessionStorage.removeItem("headImg")
                    sessionStorage.removeItem("name")
                    this.props.history.replace("/home")
                }

            })
            .catch(error => {

            })
    }
    goUserCenter() {
        sessionStorage.removeItem("key");
        this.props.history.replace("/home")
    }
    goUserSetting(){
        let shopData = sessionStorage.getItem("shopData");
        if(!!shopData&&JSON.stringify(shopData)!="{}"){
            this.props.history.replace("/admin/userSetting")
        }else{
            message.error("请先创建店铺");
        }
    }
    goback(){
        this.props.history.replace("/home")
    }


    personal(user){
        return (
            <div id="droppdown-box" className="dropdown" style={{marginRight:'10vh',marginBottom:'10px',marginLeft:'10px'}}>
                <div id="dropdownMenu" data-toggle="dropdown"  style={{verticalAlign:"middle"}}>
                    {/* 修改之前user.logo */}
                    <img src={(this.state.headImg || 'http://dotlinkface-licence.oss-cn-shanghai.aliyuncs.com/avatar-default.png')}
                        className="avatar-center" style={{display:"inline-block"}}/>
                    <span className="caret" style={{position: "static"}}></span>
                </div>
                <ul className="dropdown-menu pull-right" role="menu" 
                    aria-labelledby="dropdownMenu">
                    <li className="nav-link" role="presentation">
                        {/* <Link to={ user.role == 0 ? "/my/app" : "/my/templatePage/1" }>{ user.role == 0 ? '网站管理' : '作品管理' } </Link> */}
                        {/* 测试 */}
                        {/* <Link to={ user.role == 0 ? "/my/templatePage/1" : "/my/app" }>{ user.role == 0 ? '作品管理' : '网站管理' } </Link>                         */}
                    </li>
                    <li className="nav-link" role="presentation">
                        {/* <a role="menuitem" tabindex="-1" href="#">nav-backava</a> */}
                        {/* <Link className=""  to="/admin/userSetting">账户设置</Link> */}
                        <a onClick={this.goUserSetting.bind(this)}><span>账户设置</span> </a>   
                    </li>
                    <li  className="nav-link" role="presentation" >
                        <a onClick={this.goUserCenter.bind(this)}><span>返回首页</span> </a>   
                    </li>
                    <li className="nav-link" role="presentation">
                        <a onClick={()=>{this.quit()}}><span>退出登录</span> </a>                     
                    </li>
                </ul>
            </div>
        )
    }

    render() {
        console.log(this, "header");
        const noBoxShadow = this.props.location.pathname === "/admin/listPage";
        return (<header className="headerComponent">
            <div className="logo" style={ !!noBoxShadow ? {}:{"box-shadow":  "10px 10px 5px -5px #D2D2D2"}}>
                <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/logo.png" className="goback" onClick={this.goback.bind(this)}></img>
                <span style={{ marginLeft: '20px', fontSize: '20px' }}>管理中心</span>
            </div>
            
            {/* <div style={{ marginLeft: '60%', marginBottom: '10px' }}>
                <img src="http://dotlinkface-lingdaima.oss-cn-hangzhou.aliyuncs.com/user-center-call.png"></img>
                <span>建站专线400-800-4647</span>
            </div> */}
            {/* <ul className="numeList">
                <li onClick={this.goUserCenter.bind(this)}>返回首页</li>
                <li onClick={this.quit.bind(this)}><span className="iconfontAdmin icontuichu2"></span>退出</li>
            </ul> */}
            <div className="List">
            <Link className="color"   to="/admin/listPage">我的店铺</Link>
            {this.personal()}
            </div>
            
        </header>)
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    // actions: bindActionCreators(Store, dispatch)
})
export default withRouter(connect(mapStateToProps, null)(Header));