import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Radio } from 'antd'

import './ChooseModel.less'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom';
const location = window.location
const $ = window.$;
class ChooseModel extends Component {
    state = {
        year: 1,
        navmobile: false
    };
    componentDidMount() {
        let type = location.search ? location.search.split('=')[1] : ''
        console.log(type)
        this.setState({
            type
        })

        if ($(window).width() <= 768) {
            this.setState({
                navmobile: true
            }, () => {
                console.log(this.state.navmobile)
            })
        }
    }
    componentWillMount() {
    }

    changeYear(e) {
        console.log(e.target.value)
        this.setState({
            year: e.target.value
        })
    }

    pcmodel() {
        return (
            <div>
                <div className="model-box">
                    <div className="one-model">
                        <p className="title">比目商城</p>
                        {/* <img src={model1} className="new-img"></img> */}
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/bimu.png" alt="" className="new-img"></img>

                    </div>
                    <div className="one-model margin">
                        <p className="title">跨境电商</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/kw8.png" className="new-img"></img>


                    </div>
                    <div className="one-model margin">
                        <p className="title">烨鹿商城</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/yiwu.png" className="new-img"></img>

                    </div>
                </div>
                <div className="model-box">
                    <div className="one-model">
                        <p className="title">易客优商城</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/yikeyou.png" className="new-img"></img>

                    </div>
                    <div className="one-model margin">
                        <p className="title">校园宝典</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/xiaoyuan.png" className="new-img"></img>

                    </div>
                    <div className="one-model margin">
                        <p className="title">e商城</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/eshouye.png" alt="" className="new-img"></img>

                    </div>
                </div>
            </div>
        )
    }
    phonemodel() {
        return (
            <div>
                <div className="model-box" style={{ marginTop: '30px' }}>
                    <div className="one-model">
                        <p className="title">比目商城</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/bimu.png" alt="" className="new-img"></img>
                    </div>
                    <div className="one-model margin">
                        <p className="title">跨境电商</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/kw8.png" className="new-img"></img>
                    </div>
                </div>
                <div className="model-box">
                    <div className="one-model ">
                        <p className="title">烨鹿商城</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/yiwu.png" className="new-img"></img>

                    </div>
                    <div className="one-model margin">
                        <p className="title">校园宝典</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/xiaoyuan.png" className="new-img"></img>

                    </div>

                </div>
                <div className="model-box">
                    <div className="one-model ">
                        <p className="title">易客优商城</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/yikeyou.png" className="new-img"></img>

                    </div>

                    <div className="one-model margin">
                        <p className="title">e商城</p>
                        <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/eshouye.png" alt="" className="new-img"></img>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div id="choosemodel">

                <Header type="home" active="home" cur="home"></Header>
                {/* <div className="nav-choose">
                    <Radio.Group defaultValue="1" buttonStyle="solid" onChange={this.changeYear.bind(this)} size="large">
                        <Radio.Button value="1">全部</Radio.Button>
                        <Radio.Button value="2">饰品</Radio.Button>
                        <Radio.Button value="3">茶叶</Radio.Button>
                        <Radio.Button value="4">保健品</Radio.Button>
                        <Radio.Button value="5">数码</Radio.Button>
                        <Radio.Button value="6">服装</Radio.Button>
                        <Radio.Button value="7">化妆品</Radio.Button>
                        <Radio.Button value="8">土货</Radio.Button>
                        <Radio.Button value="9">美食</Radio.Button>
                        <Radio.Button value="10">其他</Radio.Button>
                    </Radio.Group>
                </div> */}
                {/* {this.state.type==1? <p style={{marginTop:'50px'}}>￥1888/年，免费试用30天</p> :
                this.state.type ==2 ? <p style={{marginTop:'50px'}}>￥2888/年，免费试用30天</p> :
                <p style={{marginTop:'50px'}}>￥4888/年，免费试用30天</p>} */}
                {
                    this.state.navmobile == false ? this.pcmodel() : this.phonemodel()
                }




                <Footer></Footer>

            </div>
        )
    }
}
export default ChooseModel;