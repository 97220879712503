import "./Testinterface.css";
import {Link} from 'react-router-dom';
var React = require("react");
var createReactClass = require('create-react-class');


export default  createReactClass({
    getInitialState: function() {
        return {template:[{id:123}]};
    },

    componentDidMount: function (){
        //var self = this;
        //self.getData();
    },
    openkefu(){
        var kefu101 = "http://wpa.qq.com/msgrd?v=3&uin=2435670291&site=oicqzone.com&menu=yes";
        var kefu102 = "mqqwpa://im/chat?chat_type=wpa&uin=2435670291&version=1&src_type=web&web_src=oicqzone.com";
        if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          window.open(kefu102) ;
        } else {
          window.open(kefu101) ;
        }
    },
    render: function() {
        return (
            <div className='testinterface' >
                <div id ="qrCode" className="online-contract" >
                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/sifshopAuth.jpeg" alt=""/>
                </div>
                <div id="qrText">微信扫码看案例</div>
                <div id ="qrCode" className="online-contract">
                    <img src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/wechat.jpeg" alt=""/>
                </div>
                <div id="qrText">联系客服</div>
                {/* <Link to='/msgback'>
                    <div id='Testinterface'>
                        <div className='testinterface-content'>
                            <img className='testinterface-img' src='http://lingdaima.dotlinkface.com/home/yijianfankui.png'/>
                                <a className='testinterface-title'>意见反馈</a>
                        </div>
                    </div>
                </Link> */}
            </div>
            
        );
    }
});