import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input } from 'antd';
import { withRouter } from 'react-router-dom'
import * as Admin from '../store/admin';
import http from '../../../http/http'
import './Information.less'
import UploadImgs from '../../../components/upload/UploadImgs'
import Menu from '../../../components/Menu/Menu'
import { message } from 'antd'
const { TextArea } = Input;
class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xcxData: {},
            focusActiive: '',
            headImg: []
        }
    }
    componentDidMount() {
        this.getData();
    }
    componentWillMount() {
    }
    onChange(e) {
        console.log(e)
    }
    getData() {
        http.get(`/ldmshop/mini/data/getWeChatMiniInfo?authAppId=${this.props.adminReducer.shopData.mainAppId}`)
            .then(res => {
                console.log(res, "data");
                if (res.code == 0) {
                    this.props.actions.setShopInfo(res.data)
                    let headImg = [];
                    if (!!res.data.miniHeadImg) {
                        
                        let obj = {
                            uid: 1,
                            status: 'done',
                            url: res.data.miniHeadImg,
                        }
                        headImg.push(obj);
                    }
                    this.setState({
                        xcxData: res.data,
                        headImg:headImg
                    })
                    this.refs.miniNickName.value = res.data.miniNickName || "";
                    this.refs.signature.value = res.data.signature || "";
                    this.refs.phone.value = res.data.phone || "";

                } else {
                    message.error(res.errmsg)
                }
            })
    }
    submit() {
        // this.props.history.push("/admin/Auth")
    }
    onChange = (e) => {
        let name = e.target.name;
        let xcxData = this.state.xcxData;
        xcxData[name] = e.target.value
        console.log(e.target.name)
        this.setState({
            xcxData
        })
    }
    onFocus(e) {
        this.setState({
            focusActiive: e
        })
    }
    onBlur = () => {
        this.setState({
            focusActiive: ''
        })
    }
    uploadSuccess = (file = []) => {
        this.setState({
            headImg: file
        })
    }


    render() {
        const { xcxData, focusActiive } = this.state;
        console.log()
        return <section>
        <Menu></Menu>
        <div className="Informationpages">
            <div className="title">
                基本信息
            </div>
            <div className="name">
                小程序LOGO<span>（最佳尺寸80*80）</span>
            </div>
            <div className="image">
                {/* <div className="img">
                    <img src={xcxData.miniHeadImg}></img>
                </div> */}
                <UploadImgs
                    data={this.state.headImg}
                    uploadSuccess={this.uploadSuccess}
                    isEditor={true}
                    fileLength={1}
                    length={1}
                    fileList={this.state.headImg}
                >
                    {/* <img src={xcxData.miniHeadImg}></img> */}
                    {/* <div className="upload">
                    上传
                </div> */}
                </UploadImgs>
            </div>
            <div className="name">
                小程序名称
            </div>
            <div className={["inputView", focusActiive == 'miniNickName' ? 'inputActive' : ''].join(' ')}>
                <input className="input" name="miniNickName" suffix={!!xcxData.miniNickName ? xcxData.miniNickName.length + "/20" : '0' + "/20"} ref="miniNickName" maxLength="20" onChange={this.onChange} onBlur={this.onBlur} onFocus={() => this.onFocus("miniNickName")} />
                <span>{xcxData.miniNickName ? xcxData.miniNickName.length : 0}/20</span>
            </div>
            <div className="name">
                小程序说明
            </div>
            <div className={["TextAreaView ", focusActiive == 'signature' ? 'inputActive' : ''].join(' ')}>
                <textarea className="textarea" name="signature"
                    rows="4" cols="33" maxLength="500"
                    ref="signature"
                    onChange={this.onChange} onBlur={this.onBlur} onFocus={() => this.onFocus("signature")}
                >
                </textarea>
                {/* <TextArea rows={4} value={xcxData.signature} suffix="0/500" maxLength={500} style={{ resize:"none"}}/> */}
                <div className="View1">

                </div>
            </div>
            <div className="name">
                客服联系方式
            </div>
            {/* <Input  suffix="0/20" value={xcxData.phone} onChange={this.onChange.bind(this)}/> */}
            <div className={["inputView", focusActiive == 'phone' ? 'inputActive' : ''].join(' ')}>
                <input className="input" name="phone" suffix={!!xcxData.phone ? xcxData.phone.length + "/20" : '0' + "/20"} ref="phone" maxLength="20" onChange={this.onChange} onBlur={this.onBlur} onFocus={() => this.onFocus("phone")} />
                <span>{xcxData.phone ? xcxData.phone.length : 0}/20</span>
            </div>
            <div className="fooderButton" onClick={this.submit.bind(this)}>
                保存
            </div>
        </div>
        </section>
    }
}
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Information));