import React from 'react';
import './copyright.css';
import { Link } from 'react-router-dom';
import Header from '../../common/Header'
import Footer from '../../common/Footer'
const $ = window.$;
export default class Copyright extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $('.web').scrollTop(0);
  }

  render() {
    return (
      <div>
        <Header active="login" type="login" cur="login"></Header>
        <div className="copyright-1">
          <div className="row">
            <div className="copyright-content col-md-12 col-sm-12 col-xs-12 center-block">
              <div className="copyright-header col-md-12 col-sm-12 col-xs-12">
                <div className="row">
                  <h3 className="col-md-4 col-sm-6 col-xs-6">企业资质</h3>
                </div>
              </div>
              <div className="copyright-list col-md-12 col-sm-12 col-xs-12">
              <div className="row">
              <img
                  src="https://sifshop-sass.oss-cn-hangzhou.aliyuncs.com/static/license.jpg"
                  alt="软件著作权证书"
                />
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>

    );
  }
}
