import Action from './action';

export function SetXCXId(data) {
    return {
        type: Action.CHIOSCE_XCX_ID,
        data: data
    }
}
export function selectXCXData(data) {
    return {
        type: Action.SELECT_XCX_DATA,
        data: data
    }
}
export function setXCXData(data) {
    return {
        type: Action.SET_XCX_ID,
        data: data
    }
}

export function setShopData(data) {
    return {
        type: Action.NOW_IN_SHOP,
        data: data
    }
}

export function setShopInfo(data) {
    return {
        type: Action.SHOP_INFO,
        data: data
    }
}