import "./SiteList.css"
import React, { Component } from 'react';
// var EditableSpan = require("../../common/EditableSpan")
// var SharePopover = require("../../common/sharePopover")
// var WeixinPop = require("../../common/weixinPop")
import SlideModal from '../../../common/SlideModal'
import secret from '../../../utils/secret';
// import {Link}  from 'react-router'
// import Page from '../../common/Page'
import moment from 'moment'
import * as Admin from '../store/admin';
import { Link, withRouter } from 'react-router-dom';
import icon from '../../../assets/images/xcx.jpg'
import { Ajax } from '../../../utils/ajax'
import { bindActionCreators } from 'redux';
import { message, Modal, Row, Col, Input } from 'antd'
import { connect } from 'react-redux';
// var React = require("react")
// var createReactClass = require('create-react-class');
const $ = window.$;
const location = window.location

class listPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            siteList: [],
            siteAllList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
            size: 4,
            total: 0,
            pageSize: 4,
            delId: '',
            listDefId: [],
            showModal: false,
            onOffArr: [],
            tipTitle: '移动端需要网站发布才可看',
            closeErweimaModal: false,
            xcxList: [],
            visible: false,
            item: {},
            Newname: "",
            navmobile:""
        }
    }
    // getInitialState: function () {
    //     return {
    //         siteList: [],
    //         siteAllList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    //         size: 4,
    //         total: 0,
    //         pageSize: 4,
    //         delId: '',
    //         listDefId: [],
    //         showModal: false,
    //         onOffArr: [],
    //         tipTitle: '移动端需要网站发布才可看',
    //         closeErweimaModal: false,
    //         xcxList: [],
    //         visible: false,
    //         item: {},
    //         Newname: ""
    //     };
    // },
    handleOk(e) {
        console.log(e);
        let param = {
            "introduction": this.state.item.introduction,
            "menuId": this.state.item.menuId,
            "merchantId": this.state.item.merchantId,
            "merchantName": this.state.Newname
        }
        Ajax.POST(`/ldmshop/merchant/update`,param)
        .then(res=>{
            console.log(res,"res")
            if(res.code==0){
                this.setState({
                    visible: false,
                });
                message.success("修改成功",2);
                this.getShopList()
            }else{
                message.error(res.errmsg)
            }
        })
        .catch(err=>{
            message.error(err);
        })
        
    }

    handleCancel(e) {
        console.log(e);
        this.setState({
            visible: false,
        });
    }
    getDefaultProps() {
        return {
            type: "app"
        }
    }

    ruaback() {
        this.setState({
            showModal: false
        })
        window.location.href = '/admin/Submission'
    }

    componentDidMount () {
        // this.getsitedetail();
        this.getShopList();
        if ($(window).width() <= 768) {
            this.setState({
                navmobile: true
            }, () => {
                console.log(this.state.navmobile)
            })
        }

    }
    getShopList() {
        Ajax.GET(`/ldmshop/merchant/list?pageSize=20&pageNum=1`)
            // http.get(`/ldmshop/merchant/list?pageSize=20&pageNum=1`)
            .then(res => {
                if (res.code == 0) {
                    console.log(res);
                    if (!!res.data.content) {
                        this.props.actions.setShopData(res.data.content[0]||[])
                        this.setState({
                            siteList: res.data.content
                        })
                    }


                } else {
                    message.error(res.errmsg)
                }
            })
    }

    del () {
        var self = this;
        var Id = self.state.delId;
        console.log(Id)
        Ajax.DELETE(`/ldmshop/merchant/delete/${Id}`)
            .then(res => {
                console.log(res.data)
                if (res.code == 0) {
                    Ajax.GET(`/ldmshop/merchant/list?pageSize=20&pageNum=1`)
                        .then(res => {
                            console.log(res.data)
                            window.location.href = '/admin/listPage'
                        })

                }
            })
            .catch(err => {

            })
    }

    rendBody () {
        return this.renderList()
    }

    closeModal () {
        this.setState({ showModal: false });
    }

    renderBlank(){
        if (this.props.type == "app") {
            return <div className="container blank-tips">
                <div className="tips"><a href="/template/market/all">您还没有网站，去创建一个&gt;&gt;</a></div>
            </div>
        }
    }
    showCreateLink() {
        if (this.props.type == "template") {
            return <a className="add-site-button large" href="/Choose">+创建店铺</a>
        }
        else {
            return <a className="add-site-button large" href="/Choose">+创建店铺</a>
        }
    }

    renderList() {
        return (<div className="site-list-wrap row">
            <div className="add-site col-md-12">
                <div className="col-md-12">
                    {this.showCreateLink()}
                </div>
            </div>

            <div className="">
                <div id="my-site-list" className="flex">
                    {this.renderItem()}
                </div>
            </div>
            <SlideModal modalTitle="提醒" closeModal={this.closeModal} showModal={this.state.showModal}>
                <div className="rua-popup">
                    <span>{this.state.tipTitle}</span>
                    <br />
                    <button onClick={this.ruaback}>返回</button>
                </div>
            </SlideModal>
            {this.erweimaModal()}
        </div>)
    }

    pageChange (current, size) {
        this.setState({
            currentPage: current
        });
        location.href = '/my/app?page=' + current;
    }



    delshow(id, e) {
        var infoModal = $(this.refs['info-modal']);
        var self = this;
        e.preventDefault();
        this.setState({ delId: id });
        infoModal.modal('show');
    }

    templChangeErweima(id, url, isPublish, e) {
        let self = this
        // if($(e.target).attr('data-onoff')){
        if (isPublish) {
            if (url) {
                let exurl = "http://" + url.name + ".lingdaima.cn"
                $('.erweima-img').html('');
                $('.erweima-img').qrcode({ width: 200, height: 200, text: exurl });
                $('.erweima-modal-content').fadeIn().css({ display: 'flex' });
                //替换二维码
                // $(this.refs['site-erweima-img'+id]).html("")
                // $(this.refs['site-erweima-img'+id]).qrcode({width: 200,height: 200,text: exurl});
            } else {
                this.setState({
                    tipTitle: '暂未设置网站域名',
                    showModal: true
                })
                return
            }
        } else {
            this.setState({
                tipTitle: '移动端需要网站发布才可看',
                showModal: true
            })
            return
        }
        //修改的css样式
        // $(self.refs["rua-templ-erweima"+id]).removeClass("rua").addClass("rua2").parent().find(".bd").css({"backgroundColor":"#ffffff"}).find(".des").css({"backgroundColor":"#ffffff"})
        // $(self.refs["rua-templ-pic"+id]).hide();
        // $(self.refs["site-erweima"+id]).addClass('rua-change-pic')
        // $(e.target).attr('data-onoff',"")           
        // } else{
        //     $(self.refs["rua-templ-erweima"+id]).removeClass("rua2").addClass("rua").parent().find(".bd").css({"backgroundColor":"#f9f9f9"}).find(".des").css({"backgroundColor":"#f9f9f9"})
        //     $(self.refs["rua-templ-pic"+id]).show();
        //     $(self.refs["site-erweima"+id]).removeClass('rua-change-pic')
        //     $(e.target).attr('data-onoff',"true")
        // }        
    }

    dev(id) {
        let domain = window.location.origin;
        window.open(`${domain}/designer/app/${id}`);
    }

    setup(id) {
        let domain = window.location.origin;
        location.href = `${domain}/my/set/${id}`;
    }

    trafficStatistic(id) {
        let domain = window.location.origin;
        window.open(`${domain}/my/pv/${id}`);
    }

    skipNewWeb(url, state, i) {
        console.log(this.state.siteList[i])
        if (this.state.siteList[i].mainAppId != null) {
            Ajax.GET(`/ldmshop/mini/data/getWeChatMiniInfo?authAppId=${this.state.siteList[i].mainAppId}`)
                .then(res => {
                    console.log(res);
                    if (res.code == 0) {
                        this.props.actions.setShopInfo(res.data)
                        this.props.actions.setShopData(this.state.siteList[i])
                        this.props.history.push("/admin/mainPage");
                        sessionStorage.setItem("key", 2);
                    } else {
                        message.error(res.errmsg)
                    }
                })
        }else{
            this.props.actions.setShopData(this.state.siteList[i])
            this.props.history.push("/admin/mainPage");
            sessionStorage.setItem("key", 2);
        }
    }

    goManage(id, i) {
        Ajax.GET(`/ldmshop/merchant/one/${id}`)
            .then(res => {
                if (res && res.code == 0) {
                    sessionStorage.setItem("key", 2);
                    this.props.actions.setShopData(res.data)
                    this.props.history.push("/admin/mainPage");

                } else {
                    message.error(res.errmsg)
                }
            })
    }
    goShop(id, i, site) {

        console.log(this.state.siteList[i])
        Ajax.GET(`/ldmshop/merchant/merChantToken?merChantId=${id}`)
            .then(res => {
                if (res.code == 0) {
                    let data = res;
                    data.data.roleList[0] = "ROLE_SUPER_ADMIN";
                    data.data.name = data.data.phone;
                    let logo = ''
                    if (this.state.siteList[i].logo == null) {
                        logo = ''
                    }
                    else {
                        logo = this.state.siteList[i].logo
                    }


                    let env = process.env.NODE_ENV;
                    let url = "";
                    let userData = `data=${JSON.stringify(data)}&shopName=${this.state.siteList[i].merchantName}&miniHeadImg=${logo}&shopId=${this.state.siteList[i].merchantId}&menuId=${this.state.siteList[i].menuId}&appId=${this.state.siteList[i].mainAppId}`
                    userData = secret.Encrypt(userData)
                    if (env === 'development') {
                        url = `http://localhost:8000/user/login?${userData}`
                    } else if (env === 'production') {
                        if (site === 'new') {
                            url = `http://suyuan.sifshop.cn/login/${userData}`
                        } else {
                            url = `http://admin.sifshop.cn/user/login?${userData}`
                        }
                    }
                    window.location.href = url;
                } else {
                    message.error(res.errmsg);
                }
            })
    }
    updata(item) {
        console.log(item);
        this.setState({
            item: item,
            visible: true,
            Newname:item.merchantName
        })
    }
    renderItem() {
        console.log(this.state.siteList)
        var result = [];
        for (var i = 0; i < this.state.siteList.length; i++) {
            var site = this.state.siteList[i];
            // var data = new Date(site.updateTimestamp);
            // var month  =  (data.getMonth() + 1) >= 10 ? (data.getMonth() + 1) : '0'+(data.getMonth() + 1);
            // var date =  parseInt(data.getDate()) >= 10 ? data.getDate() : '0' + data.getDate();
            // var updatatime = data.getFullYear() + '-' + month  + '-' + date
            site.webUrl = site.subdomain ? "http://" + site.subdomain.name + ".lingdaima.cn" : ''
            var item = (
                <div className="templ flex" key={site.id} style={{ marginLeft: '15px' }}>
                    {/* {console.log(site.id)} */}
                    {/* <div className="rua" onClick={this.templChangeErweima.bind(this, site.id, site.subdomain, site.isPublish)} data-onoff="true" ref={"rua-templ-erweima" + site.id} alt="点击查看二维码，手机扫一扫浏览网站"></div> */}
                    <div className="bd col-md-5" ref={"rua-bd-hei" + site.id}>
                        <div className="site-erweima" ref={"site-erweima" + site.id} id={"site-erweima" + site.id}>
                            <div className="site-erweima-img" ref={"site-erweima-img" + site.id}><img src='https://dotlinkface-lingdaima.oss-cn-hangzhou.aliyuncs.com/test/site_erweima.png' /></div>
                            <div className="erweima-friend-msg"><img src="http://lingdaima.dotlinkface.com/saoyisao-icon.png" /><span>用手机扫一扫浏览网站</span></div>
                        </div>
                        <a href="javascript:;" onClick={!site.expired ?this.skipNewWeb.bind(this, site.webUrl, site.isPublish, i):""} style={{height:"300px",display:"flex",justifyContent:"center",alignItems:"center",background:"#fff"}} className={'url-' + site.id} ref={"rua-templ-pic" + site.id}><img
                            className={'bd-img' + site.publishLogo ? 'site-capture' : 'site-capture height247'} src={(site.logo ? site.logo : "https://dotlinkface-lingdaima.oss-cn-hangzhou.aliyuncs.com/1585555800453") || 'http://lingdaima.dotlinkface.com/template_bg.png'} /></a>
                    </div>
                    <div className="des col-md-6">
                        <div className={"des-title "}>
                            <span>{site.merchantName || ''}</span>
                            <span className="icon iconfont iconfont2 icon-bianji2" onClick={this.updata.bind(this, site)}></span>
                            <span className="des-border"></span>
                            {/* <i className="xiaochengxu"></i> */}
                            {/* <i className="phone" onClick={this.templChangeErweima.bind(this, site.id, site.subdomain, site.isPublish)}></i>                          */}
                            <span className="des-class">{site.mainServiceClass}{site.menuId == 1 ? "基础版" : ""}{site.menuId == 2 ? "分销版" : ""}{site.menuId == 3 ? "直播版" : ""}</span>
                        </div>

                        {/* <div className={"des-domain des-row col-md-12 "} ><a href='' className={'domain-'+site.id} target="_blank"></a> </div> */}
                        <div className="site-status des-row col-md-12">
                            <div className={"col-md-6 status-" + site.clientId}>状态：{site.miniStatusStr}</div>
                            {/* <div className="col-md-6">授权编辑：{site.authorization || '未授权'}<a href="" className="desc-tips">[授权]</a></div>*/}
                        </div>

                        <div className="last-time des-row col-md-12">
                            {/* 最后编辑时间： {updatatime} */}
                            到期时间：{site.expireText }
                        </div>

                        {/* <div className="host des-row col-md-12">   空间：{'阿里云（香港）'}</div>     */}
                        <div className="expire-time des-row col-md-12 ">
                        {/* <a href="" className="desc-tips">[续费]</a> */}
                        </div>

                        <div className="operate-btns col-md-12" >
                            {/* {(site.id !== 264)?
                                <a className="black-btn" onClick={this.dev.bind(this,site.id)}>管理网站</a>: null
                            } */}
                            {console.log(!site.expired)}
                            <span className={`black-btn ${!site.expired?'':'isexpired'}`}  onClick={ !site.expired ? this.goManage.bind(this, site.merchantId, i):""}>小程序配置</span>
                            <span className={`black-btn ${!site.expired?'':'isexpired'}`} onClick={!site.expired ?this.goShop.bind(this, site.merchantId, i, 'old'):""}>店铺管理</span>
                            <br/><br/>
                            <span className={`black-btn ${!site.expired?'':'isexpired'}`} onClick={!site.expired ?this.goShop.bind(this, site.merchantId, i, 'new'):""}>溯源管理</span>
                            {/* <span style={{width:"40%",    display: "inline-block"}}>{site.expireText}</span> */}
                            
                            {/* <Link to={'/my/set/' + site.id } className="black-btn"> 设置小程序</Link> */}
                            {/* <a className="black-btn" onClick={this.setup.bind(this,site.id)}>设置网站</a> */}
                            {/* <a className="black-btn" onClick={this.trafficStatistic.bind(this,site.id)}>流量统计</a> */}
                            <a className="icon iconfont iconfont1 icon-shanchu" onClick={this.delshow.bind(this, site.merchantId)}></a>
                        </div>
                    </div>

                </div>
            );
            result.push(item);
        }
        return result;
    }

    renderModal () {
        return <div
            ref="info-modal"
            id="info-modal"
            className=" modal fade"
            role="dialog"
            data-backdrop="true"
            aria-labelledby="infoLabel"
            aria-hidden="true">
            <div className="modal-dialog tip-success tip-del" role="document">
                <div className="modal-content">
                    <div className='tip-body'>
                        <div className='tip-success-img'>
                            <span className="delete icon iconfont icon-delete-copy"  ></span>
                        </div>
                        <div className='tip-success-txt'>
                            <h3>
                            您确定删除该店铺吗
                            </h3>
                        </div>
                        <div className='tip-success-btn'>
                            <div onClick={this.del.bind(this)}>
                                确认
                            </div>
                            <div data-dismiss="modal">
                                取消
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    closeErweimaModal () {
        $('.erweima-modal-content').fadeOut()
    }
    erweimaModal() {
        return <div className="erweima-modal-content">
            <div className="erweima-modal-box">
                <div className="erweima-modal-box-title">
                    <span>移动端</span>
                    <span className="icon iconfont icon-close" onClick={this.closeErweimaModal}></span>
                </div>
                <div className="erweima-modal-box-content">
                    <div className="erweima-img">
                        {/* <img /> */}
                    </div>
                    <p><img src="http://lingdaima.dotlinkface.com/saoyisao-icon.png" /><span>打开手机扫一扫浏览网站</span></p>
                </div>
            </div>
        </div>
    }

    onChange(e) {
        this.setState({
            Newname: e.target.value
        })
    }

    render () {
        return (

            <div className="site-list" id="listPage">

                <div className="container site-list-container">
                    {this.rendBody()}
                </div>
                {this.renderModal()}
                { this.state.navmobile ? <div className="webTip">去PC端管理更方便:https://www.sifshop.cn</div> :null}
                <Modal
                    title="请输入新的名称"
                    visible={this.state.visible}
                    onOk={()=>this.handleOk()}
                    onCancel={this.handleCancel.bind(this)}
                    cancelText="取消"
                    okText="确认"
                >
                    <Row type="flex" justify="center" align="middle">
                
                        <Col span={18} >
                            <Input value={this.state.Newname} onChange={this.onChange.bind(this)} />
                        </Col>
                    </Row>
                </Modal>
            </div>

        );
    }

};
const mapStateToProps = ({ adminReducer }) => {
    return { adminReducer }
}
const mapDispatchToProps = dispatch => ({
    // actions: bindActionCreators(Store, dispatch)
    actions: bindActionCreators(Admin, dispatch)
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(listPage));